/* @flow */

import './Section.css';
import * as React from 'react';
import { type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, type TILE_CONFIG_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { PictoArrowLeft, PictoArrowRight } from '@ntg/components/dist/pictos/Element';
import { getTileConfig, getTileTypeClass } from '../../../helpers/ui/section/tile';
import { type Dispatch } from '../../../redux/types/types';
import Item from '../item/Item';
import { Localizer } from '@ntg/utils/dist/localization';
import { type NETGEM_API_V8_FEED } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { type NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import { type SwipeEventData } from '../../../helpers/ui/section/types';
import Swipeable from '../../swipeable/swipeable';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createSectionPagination } from '../../../helpers/ui/section/Pagination';
import { getTileWidthPlusMargin } from '../../../helpers/ui/constants';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import sendV8SectionFeedRequest from '../../../redux/netgemApi/actions/v8/feed';

type ReduxSectionDispatchToPropsType = {|
  +localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, signal?: AbortSignal) => Promise<any>,
|};

type DefaultProps = {|
  +isInExploreModal?: boolean,
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

type SectionPropType = {|
  ...DefaultProps,
  +section: NETGEM_API_V8_SECTION,
|};

type CompleteSectionPropType = {|
  ...SectionPropType,
  ...ReduxSectionDispatchToPropsType,
|};

type SectionStateType = {|
  feed: NETGEM_API_V8_FEED,
  itemCountPerPage: number,
  maxPageIndex: number,
  pageIndex: number,
|};

const InitialState: SectionStateType = Object.freeze({
  feed: [],
  itemCountPerPage: -1,
  maxPageIndex: 0,
  pageIndex: 0,
});

// Number of pages actually rendered (even if only partially visible)
const PAGE_RENDER_COUNT = 2;

// Used to transform velocity in a page step when swiping a section
const PAGE_VELOCITY_STEP: number = 5;

class SectionView extends React.PureComponent<CompleteSectionPropType, SectionStateType> {
  abortController: AbortController;

  itemWidthPlusMargin: number;

  sectionName: string;

  slider: HTMLElement | null;

  sliderPositions: Array<number>;

  tileConfig: TILE_CONFIG_TYPE;

  static defaultProps: DefaultProps = {
    isInExploreModal: false,
    onItemClick: undefined,
  };

  constructor(props: CompleteSectionPropType) {
    super(props);

    const {
      section,
      section: { id },
    } = props;

    this.abortController = new AbortController();
    this.sectionName = id;
    this.slider = null;
    this.sliderPositions = [];
    this.tileConfig = getTileConfig(section);
    this.itemWidthPlusMargin = getTileWidthPlusMargin(this.tileConfig.type);

    this.state = { ...InitialState };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions, { passive: true });
    this.loadData();
  }

  componentDidUpdate(prevProps: CompleteSectionPropType, prevState: SectionStateType) {
    const { feed, itemCountPerPage, pageIndex } = this.state;
    const { feed: prevFeed, itemCountPerPage: prevItemCountPerPage } = prevState;

    if (itemCountPerPage !== prevItemCountPerPage) {
      this.createPageList(prevItemCountPerPage);
      return;
    }

    if (prevFeed !== feed && feed.length > 0) {
      if (prevFeed.length !== feed.length) {
        // Feed has changed: re-render everything
        this.createPageList();
      }
    } else if (prevState.pageIndex !== pageIndex) {
      this.goToPage(pageIndex);
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component SectionChannelGroup will unmount');

    window.removeEventListener('resize', this.updateDimensions, { passive: true });
  }

  loadData = () => {
    const { localSendV8SectionFeedRequest, section } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8SectionFeedRequest(section, signal)
      .then((response) => {
        signal.throwIfAborted();

        const feed = ((response: any): NETGEM_API_V8_FEED);
        this.setState({ feed });
        this.updateDimensions();
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ feed: [] })));
  };

  createPageList: (previousItemCountPerPage?: number) => void = (previousItemCountPerPage) => {
    const { feed, itemCountPerPage, pageIndex: previousPageIndex } = this.state;
    const { itemWidthPlusMargin } = this;

    if (itemCountPerPage === -1) {
      return;
    }

    const { maxPageIndex, pageIndex, sliderPositions } = createSectionPagination(feed, itemWidthPlusMargin, itemCountPerPage, previousPageIndex, previousItemCountPerPage);

    this.sliderPositions = sliderPositions;
    this.setState({
      maxPageIndex,
      pageIndex,
    });
  };

  calculateItemCountPerPage = (): number => {
    const { itemWidthPlusMargin, slider } = this;

    if (!slider || !(slider.parentElement instanceof HTMLElement)) {
      return -1;
    }

    return Math.max(1, Math.floor(slider.parentElement.offsetWidth / itemWidthPlusMargin));
  };

  updateDimensions = () => {
    const itemCountPerPage = this.calculateItemCountPerPage();

    this.setState({ itemCountPerPage }, () => {
      if (itemCountPerPage === -1) {
        // Slider was not rendered yet, so let schedule another run
        setTimeout(this.updateDimensions, 0);
      }
    });
  };

  checkGetPageStep = (pageStep?: number): number => (typeof pageStep === 'number' && pageStep > 1 ? pageStep : 1);

  handleNextButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.goToNextPage();
  };

  goToNextPage = (pageStep?: number) => {
    const { maxPageIndex, pageIndex } = this.state;

    const step = this.checkGetPageStep(pageStep);
    this.goToPage(Math.min(pageIndex + step, maxPageIndex));
  };

  handlePrevButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.goToPreviousPage();
  };

  goToPreviousPage = (pageStep?: number) => {
    const { pageIndex } = this.state;

    const step = this.checkGetPageStep(pageStep);
    this.goToPage(Math.max(0, pageIndex - step));
  };

  goToPage = (pageIndex: number) => {
    const { slider, sliderPositions } = this;

    if (!slider || pageIndex < 0 || pageIndex >= sliderPositions.length) {
      return;
    }

    const posX = sliderPositions[pageIndex];
    slider.style.transform = `translateX(${posX}px) translateZ(0)`;

    this.setState({ pageIndex });
  };

  handleOnSwiping = (eventData: SwipeEventData) => {
    const { deltaX } = eventData;
    const { maxPageIndex, pageIndex } = this.state;
    const { slider, sliderPositions } = this;

    if (!slider || maxPageIndex === 0) {
      return;
    }

    const posX = sliderPositions[pageIndex];
    const newPosX = posX + deltaX;

    slider.style.transform = `translateX(${newPosX}px) translateZ(0)`;
  };

  handleOnSwiped = (eventData: SwipeEventData) => {
    const { dir, velocity } = eventData;
    const { maxPageIndex } = this.state;

    if (maxPageIndex === 0) {
      return;
    }

    const pageStep = Math.ceil(velocity / PAGE_VELOCITY_STEP);

    if (dir === 'Left') {
      this.goToNextPage(pageStep);
    } else if (dir === 'Right') {
      this.goToPreviousPage(pageStep);
    }
  };

  renderTiles = (): Array<React.Node> => {
    const { isInExploreModal, onItemClick } = this.props;
    const {
      feed,
      feed: { length: itemCount },
      itemCountPerPage,
      pageIndex,
    } = this.state;
    const { tileConfig } = this;

    const indexEnd = Math.min((pageIndex + PAGE_RENDER_COUNT) * itemCountPerPage, itemCount);
    const tiles: Array<React.Node> = [];

    for (let i = 0; i < indexEnd; ++i) {
      const { [i]: item } = feed;

      tiles.push(<Item isInExploreModal={isInExploreModal} item={item} key={item.id} onItemClick={onItemClick} tileConfig={tileConfig} />);
    }

    return tiles;
  };

  render(): React.Node {
    const { section } = this.props;
    const { feed, maxPageIndex, pageIndex } = this.state;
    const {
      tileConfig: { imageLayout, type },
    } = this;

    if (feed.length === 0) {
      // Do not display empty section
      return null;
    }

    // Tile type to CSS class conversion (e.g. 'gemtv.big' -> 'gemtv big')
    const tileTypeClass = getTileTypeClass(type);

    // Display only visible pages
    const tiles = this.renderTiles();

    const prevButton =
      pageIndex > 0 ? (
        <div className='navigationButton previous' onClick={this.handlePrevButtonOnClick}>
          <PictoArrowLeft forceHoverEffect />
        </div>
      ) : null;

    const nextButton =
      pageIndex < maxPageIndex ? (
        <div className='navigationButton next' onClick={this.handleNextButtonOnClick}>
          <PictoArrowRight forceHoverEffect />
        </div>
      ) : null;

    return (
      <div className={clsx('section', 'channelGroupSection', tileTypeClass, imageLayout)} id={`section_${this.sectionName}`}>
        <div className='header'>
          <div className='sectionTitleContainer'>{getTranslatedText(section.title, Localizer.language)}</div>
        </div>
        <Swipeable onSwiped={this.handleOnSwiped} onSwiping={this.handleOnSwiping} trackMouse>
          <div
            className='slider'
            ref={(instance) => {
              this.slider = instance;
            }}
          >
            {tiles}
          </div>
        </Swipeable>
        {prevButton}
        {nextButton}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxSectionDispatchToPropsType => {
  return {
    localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, signal?: AbortSignal): Promise<any> => dispatch(sendV8SectionFeedRequest(section, null, signal)),
  };
};

const Section: React.ComponentType<SectionPropType> = connect(null, mapDispatchToProps, null, { forwardRef: true })(SectionView);

export default Section;
