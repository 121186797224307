/* @flow */

import type { KeyValuePair } from '../types';

export const MessengerEvents: KeyValuePair<string> = Object.freeze({
  ACCOUNT_CREATED: 'account_created_event',
  APPLICATION_FULLY_LOADED: 'application_fully_loaded_event',
  AUTHENTICATION_REQUIRED: 'authentication_required_event',
  AUTHENTICATION_TOKEN_CHANGED: 'authentication_token_changed_event',
  AVENUE_RESET: 'reset_avenue_event',
  CAROUSEL_HIDE: 'carousel_hide_event',
  CAROUSEL_SET_CURRENT_INDEX: 'carousel_set_current_index_event',
  CAROUSEL_SHOW: 'carousel_show_event',
  CAROUSEL_SHOW_DEBUG: 'carousel_show_debug_event',
  CHECK_PENDING_OPERATION: 'check_pending_operation_event',
  CHECK_VERSION: 'check_version_event',
  CLEAR_NPVR: 'clear_npvr_event',
  CLEAR_PURCHASE_LIST: 'clear_purchase_list_event',
  CLEAR_VIEWING_HISTORY: 'clear_viewing_history_event',
  CLEAR_WISH_LIST: 'clear_wish_list_event',
  CLOSE_CHANNEL_LIST: 'close_channel_list_event',
  CONSENT_OPEN_SETTINGS: 'open_consent_settings_event',
  CONSENT_RESET: 'reset_consent_event',
  DEBUG_SHOW_FINISHING_LIVE_PROGRAMS: 'debug_show_finishing_live_programs_event',
  DOWNLOAD_SUCCESSFUL: 'download_successful_event',
  ENLARGE_VIDEO_CAROUSEL_MASK: 'show_video_carousel_mask_event',
  EPISODES_FEED_LOADED: 'episodes_feed_loaded_event',
  FLUSH_COLLECTOR: 'flush_collector_event',
  IMAGE_CAROUSEL_SET: 'image_carousel_set_event',
  ITEM_CLICKED: 'item_clicked_event',
  LOG_OUT: 'log_out_event',
  MANAGE_SUPER_STREAM: 'manage_super_stream_event',
  MODAL_CONFIRMATION_CLOSED: 'modal_confirmation_closed_event',
  MOVE_TO_TOP: 'move_to_top_event',
  NOTIFY_CLOSE: 'notify_close_event',
  NOTIFY_ERROR: 'notify_error_event',
  NOTIFY_INFO: 'notify_info_event',
  NOTIFY_SUCCESS: 'notify_success_event',
  NOTIFY_WARNING: 'notify_warning_event',
  OPEN_CARD: 'open_card_event',
  OPEN_EXPLORE_MODAL: 'open_explore_modal_event',
  OPEN_GLOBAL_SETTINGS: 'open_global_settings_event',
  OPEN_GRID_AVENUE: 'open_grid_avenue_event',
  OPEN_NPVR_MANAGEMENT_SCREEN: 'open_npvr_management_screen_event',
  OPEN_PLAYER: 'open_player_event',
  OPEN_TILE_TYPES_AVENUE: 'open_tile_types_avenue_event',
  OPEN_USER_ACCOUNT: 'open_user_account_event',
  REDUCE_VIDEO_CAROUSEL_MASK: 'hide_video_carousel_mask_event',
  REFRESH_AUTHENTICATION_TOKEN: 'refresh_authentication_token_event',
  REFRESH_HUB: 'refresh_hub_event',
  REFRESH_NPVR: 'refresh_npvr_event',
  REFRESH_PURCHASE_LIST: 'refresh_purchase_list_event',
  REFRESH_RECORDINGS_SECTION: 'refresh_recordings_section_event',
  REFRESH_VIEWING_HISTORY: 'refresh_viewing_history_event',
  REFRESH_WISH_LIST: 'refresh_wish_list_event',
  REGISTER_AS_GUEST: 'register_as_guest_event',
  RESTART_APP: 'restart_app_event',
  RESTORE_POSITION: 'restore_position_event',
  SECTION_SLIDING_UPDATE: 'update_section_sliding_event',
  SHOW_GUEST_MODE: 'show_guest_mode_event',
  SHOW_LEGAL_NOTICE: 'show_legal_notice_event',
  SHOW_PODS: 'show_pods_event',
  SHOW_SIGN_IN: 'show_sign_in_event',
  SHOW_SUBSCRIBE: 'show_subscribe_event',
  STASH_COLLECTOR: 'stash_collector_event',
  SUBSCRIBED: 'subscribed_event',
  TOGGLE_DEBUG_MODE: 'toggle_debug_mode_event',
  UNSTASH_COLLECTOR: 'unstash_collector_event',
  VIDEO_CAROUSEL_ENDED: 'video_carousel_ended_event',
  VIDEO_CAROUSEL_ENTER_FULLSCREEN: 'video_carousel_enter_fullscreen_event',
  VIDEO_CAROUSEL_EXIT_FULLSCREEN: 'video_carousel_exit_fullscreen_event',
  VIDEO_CAROUSEL_FULLSCREEN_EXITED: 'video_carousel_fullscreen_exited_event',
  VIDEO_CAROUSEL_SET: 'video_carousel_set_event',
  VIDEO_CAROUSEL_STOP: 'video_carousel_stop_event',
  VIDEO_CAROUSEL_TOGGLE_FULLSCREEN: 'video_carousel_toggle_fullscreen_event',
  VIDEO_CAROUSEL_TRAILER_HIDDEN: 'video_carousel_trailer_hidden_event',
  VIDEO_CAROUSEL_TRAILER_VISIBLE: 'video_carousel_trailer_visible_event',
  VIDEO_CAROUSEL_UPDATE_PROGRESS: 'video_carousel_update_progress_event',
  WATCH_RIGHTS_CHANGE: 'watch_rights_change_event',
});
