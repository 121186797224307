/* @flow */

import * as React from 'react';
import { type ExportedPictoPropType, type PictoPropType } from './Base';
// $FlowFixMe: Flow cannot resolve clsx
import clsx from 'clsx';

const PictoHOC = (WrappedComponent: React.ComponentType<PictoPropType>, renderSvgElement: () => React.Node, extraClasses?: string): React.ComponentType<ExportedPictoPropType> => {
  class PictoHocTemplate extends React.PureComponent<ExportedPictoPropType, any> {
    render(): React.Node {
      const { className = '', data, draggable, forceHoverEffect, forwardedRef, hasBackground, isDisabled, key, onClick, onMouseDown, onMouseEnter, onMouseLeave, onMouseMove, onMouseUp } = this.props;

      return (
        <WrappedComponent
          className={clsx(className, extraClasses)}
          data={data}
          draggable={draggable}
          forceHoverEffect={forceHoverEffect}
          forwardedRef={forwardedRef}
          hasBackground={hasBackground}
          isDisabled={isDisabled}
          key={key}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          svgElement={renderSvgElement()}
        />
      );
    }
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return React.forwardRef((props, ref) => <PictoHocTemplate {...props} forwardedRef={ref} />);
};

export default PictoHOC;
