/* @flow */

import './CardModal.css';
import * as React from 'react';
import {
  type ADD_TO_FAVORITE_DATA_TYPE,
  type ADD_TO_WISHLIST_DATA_TYPE,
  type BASE_VOD_PURCHASE_DATA_TYPE,
  PendingOperationKind,
  PendingOperationReason,
  type VOD_PURCHASE_DATA_TYPE,
  clearPendingOperation,
  storePendingOperation,
} from '../../../helpers/rights/pendingOperations';
import { BOVodAssetStatus, getTitIdFromProviderInfo, getVodLocationFromVtiId, getVodStatusFromLocations, hasBeenPurchased, hasTrailer } from '../../../helpers/videofutur/metadata';
import { BroadcastStatus, WebAppHelpersLocationStatus, getBroadcastStatus, getLocationStatus } from '../../../helpers/ui/location/Format';
import { CARD_BACKGROUND_IMAGE_HEIGHT, CARD_BACKGROUND_IMAGE_WIDTH, CHANNEL_IMAGE_HEIGHT, CHANNEL_IMAGE_WIDTH, VOD_TILE_HEIGHT, VOD_TILE_WIDTH } from '../../../helpers/ui/constants';
import {
  type CARD_DATA_MODAL_TYPE,
  type CardModalStateType,
  type CompleteCardModalPropType,
  ImageDisplayType,
  REFRESH_TIMEOUT,
  type ReduxCardModalDispatchToPropsType,
  type ReduxCardModalReducerStateType,
} from './CardModalConstsAndTypes';
import { FEATURE_NPVR, FEATURE_SUBSCRIPTION } from '../../../redux/appConf/constants';
import { IMAGE_TAG_NO_TEXT, findLandscapeImageId } from '../../../helpers/ui/metadata/image';
import {
  ItemContent,
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
  VodKind,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import {
  METADATA_KIND_PROGRAM,
  METADATA_KIND_SERIES,
  type MetadataKind,
  type NETGEM_API_V8_METADATA,
  type NETGEM_API_V8_METADATA_PROGRAM,
  type NETGEM_API_V8_METADATA_SERIES,
} from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { MILLISECONDS_PER_HOUR, formatDate, formatExpirationDate } from '../../../helpers/dateTime/Format';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NETGEM_API_V8_AUTHENT_REALM, NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR } from '../../../libs/netgemLibrary/v8/types/Realm';
import {
  type NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES,
  RecordingOutcome,
} from '../../../libs/netgemLibrary/v8/types/Npvr';
import { PictoClock, PictoFailure, PictoHeartFull, PictoRecord, PictoWarning } from '@ntg/components/dist/pictos/Element';
import PricingVod, { ContextKind as PricingContext } from '../../pricingVod/PricingVod';
import RecordButton, { DisplayMode, ContextKind as RecordContext } from '../../recordButton/RecordButton';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import { addToWishlist, removeFromWishlist } from '../../../redux/netgemApi/actions/personalData/wishlist';
import { channelHasStartover, getChannelImageId } from '../../../helpers/channel/helper';
import { getDisplayType, getLiveProgress, getWatchingStatus, isFavorite, isItemPlayable, updateTitle } from './helper';
import { getEpisodeAndSeriesRecordStatus, getScheduledRecordingIdFromRecordingId } from '../../../helpers/npvr/recording';
import { getEpisodeIndexAndTitle, getStartAndEndTimes, getSynopsis, getTagline, getTitle, renderProgramDetails } from '../../../helpers/ui/metadata/Format';
import { getImageSourceUrl, getImageUrl } from '../../../redux/netgemApi/actions/v8/metadataImage';
import { hideModal, showVodPurchaseModal } from '../../../redux/modal/actions';
import { isFavoriteGranted, isWishlistGranted } from '../../../helpers/rights/wishlist';
import { logError, showDebug } from '../../../helpers/debug/debug';
import sendV8MetadataLocationRequest, { getVodLocations } from '../../../redux/netgemApi/actions/v8/metadataLocation';
import AccurateTimestamp from '../../../helpers/dateTime/AccurateTimestamp';
import ButtonFX from '../../buttons/ButtonFX';
import CardAvenue from './CardModalAvenue';
import type { CombinedReducers } from '../../../redux/reducers';
import CreditsView from '../../credits/Credits';
import type { Dispatch } from '../../../redux/types/types';
import { ExtendedItemType } from '../../../helpers/ui/item/types';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import type { ModalState } from '../../../redux/modal/reducers';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { NETGEM_API_V8_RAW_FEED } from '../../../libs/netgemLibrary/v8/types/FeedResult';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../libs/netgemLibrary/v8/types/RequestResponse';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { PROGRAM_INFO } from '../../../helpers/ui/metadata/Types';
import { RegistrationType } from '../../../redux/appRegistration/types/types';
import StatusPicto from '../../statusPicto/StatusPicto';
import { TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { Undefined } from '@ntg/utils/dist/types';
import WishlistCache from '../../../helpers/wishlist/WishlistCache';
import { addLinkedData } from '../../../helpers/jsHelpers/linkedData';
import addToFavoriteList from '../../../redux/netgemApi/actions/videofutur/addFavorite';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { filterCredits } from '../../../helpers/ui/metadata/Exclusion';
import { getDistributorId } from '../../../helpers/ui/item/distributor';
import { getItemContentType } from '../../../libs/netgemLibrary/v8/helpers/Item';
import { getPurchaseInfoPerAsset } from '../../../redux/netgemApi/actions/v8/purchaseInfo';
import { getStartOverItem } from '../../../libs/netgemLibrary/v8/helpers/CatchupForAsset';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isItemLiveOrAboutToStart } from '../../../libs/netgemLibrary/v8/helpers/Feed';
import { isPlaybackGranted } from '../../../helpers/rights/playback';
import { isVodItem } from '../../../helpers/ui/item/metadata';
import { parseBoolean } from '../../../helpers/jsHelpers/parser';
import removeFromFavoriteList from '../../../redux/netgemApi/actions/videofutur/deleteFavorite';
import { resetSectionPageIndices } from '../../../redux/ui/actions';
import sendV8LocationCatchupForAssetRequest from '../../../redux/netgemApi/actions/v8/catchupForAsset';
import sendV8MetadataRequest from '../../../redux/netgemApi/actions/v8/metadata';
import { sendV8RecordingsMetadataRequest } from '../../../redux/netgemApi/actions/v8/recordings';

const InitialState = Object.freeze({
  authority: undefined,
  backgroundImageUrl: null,
  broadcastStatus: BroadcastStatus.Unknown,
  canBePlayed: false,
  channelImageUrl: null,
  contentType: ItemContent.Unknown,
  followedPictoClassName: '',
  imageDisplayType: ImageDisplayType.Unset,
  isDeletable: false,
  isFollowed: null,
  isLiveRecording: false,
  isSeries: false,
  isWaitingForDeleteConfirmation: false,
  isWaitingForRecordConfirmation: false,
  now: 0,
  portraitImageUrl: null,
  previewCatchupScheduledEventId: null,
  programMetadata: null,
  purchaseInfo: null,
  seriesMetadata: null,
  startoverItem: null,
  tvLocationMetadata: null,
  vodLocationsMetadata: null,
  vodStatus: null,
});

class CardModalView extends React.PureComponent<CompleteCardModalPropType, CardModalStateType> {
  abortController: AbortController;

  broadcastStatusRefreshTimer: TimeoutID | null;

  failedRecordingId: ?string;

  recordOutcome: ?RecordingOutcome;

  slider: React.ElementRef<any> | null;

  timeRefreshTimer: TimeoutID | null;

  warningScheduledEventId: ?string;

  warningScheduledRecordingId: ?string;

  constructor(props: CompleteCardModalPropType) {
    super(props);

    this.abortController = new AbortController();
    this.failedRecordingId = null;
    this.recordOutcome = null;
    this.broadcastStatusRefreshTimer = null;
    this.slider = null;
    this.timeRefreshTimer = null;
    this.warningScheduledEventId = null;
    this.warningScheduledRecordingId = null;

    const { cardData } = props;
    if (cardData) {
      const {
        isOpenFromPlayer,
        item: { type },
      } = cardData;
      // When opened from player, only display episode card (not series card)
      this.state = {
        ...InitialState,
        isSeries: !isOpenFromPlayer && type === ItemType.Series,
      };
    } else {
      this.state = { ...InitialState };
    }
  }

  componentDidMount() {
    Messenger.on(MessengerEvents.EPISODES_FEED_LOADED, this.loadVodData);
    this.initialize();
  }

  componentDidUpdate(prevProps: CompleteCardModalPropType, prevState: CardModalStateType) {
    const { cardData, favoriteList, localResetSectionPageIndices, npvrRecordingsList, npvrScheduledRecordingsList, purchaseList, wishlist, wishlistStatus } = this.props;
    const {
      cardData: prevCardData,
      favoriteList: prevFavoriteList,
      npvrRecordingsList: prevNpvrRecordingsList,
      npvrScheduledRecordingsList: prevNpvrScheduledRecordingsList,
      purchaseList: prevPurchaseList,
      wishlist: prevWishlist,
      wishlistStatus: prevWishlistStatus,
    } = prevProps;
    const { broadcastStatus, contentType, isFollowed, previewCatchupScheduledEventId, vodLocationsMetadata } = this.state;
    const {
      broadcastStatus: prevBroadcastStatus,
      contentType: prevContentType,
      isFollowed: prevIsFollowed,
      previewCatchupScheduledEventId: prevPreviewCatchupScheduledEventId,
      vodLocationsMetadata: prevVodLocationsMetadata,
    } = prevState;
    const { broadcastStatusRefreshTimer } = this;

    if (!cardData) {
      return;
    }

    if (vodLocationsMetadata !== prevVodLocationsMetadata || purchaseList !== prevPurchaseList) {
      this.updateVodStatus();
    }

    if ((!prevPurchaseList && purchaseList) || (!prevFavoriteList && favoriteList) || (wishlistStatus === LoadableStatus.Loaded && prevWishlistStatus !== LoadableStatus.Loaded)) {
      this.checkPendingOperation();
    }

    if (favoriteList !== prevFavoriteList) {
      // VOD/SVOD case
      this.updateIsFollowed();
    }

    if (isFollowed !== prevIsFollowed) {
      this.updateFollowedPictoClassName(prevIsFollowed, isFollowed);
    }

    if (contentType !== prevContentType) {
      if (!broadcastStatusRefreshTimer) {
        this.updateBroadcastStatus();
      }
    }

    const {
      item: { locType, selectedLocation, selectedProgramId, type },
    } = cardData;

    if (!prevCardData) {
      // No card was loaded before (not sure this could happen...)
      this.updateContentType();
    } else {
      const {
        item: { locType: prevLocType, selectedLocation: prevSelectedLocation, selectedProgramId: prevSelectedProgramId, type: prevType },
      } = prevCardData;

      if (locType !== prevLocType) {
        this.updateBroadcastStatus();
      }

      if (locType !== prevLocType || type !== prevType || selectedProgramId !== prevSelectedProgramId || selectedLocation !== prevSelectedLocation) {
        // Reset saved positions for all sections in the card (using "card_" prefix)
        localResetSectionPageIndices('card_');

        const { slider } = this;
        if (slider) {
          // Scroll to top when card is updated
          slider.scrollTop = 0;
        }

        this.updateContentType();
        return;
      }

      if (broadcastStatus !== prevBroadcastStatus) {
        if (broadcastStatus === BroadcastStatus.Live) {
          // Item became live
          this.startTimeRefreshTimer();
        } else {
          // Item is not live anymore
          this.resetTimeRefreshTimer();
        }
      }

      if (
        npvrRecordingsList !== prevNpvrRecordingsList ||
        npvrScheduledRecordingsList !== prevNpvrScheduledRecordingsList ||
        wishlist !== prevWishlist ||
        broadcastStatus !== prevBroadcastStatus ||
        previewCatchupScheduledEventId !== prevPreviewCatchupScheduledEventId
      ) {
        this.updateCard();
      }
    }
  }

  componentWillUnmount() {
    const { applicationName } = this.props;
    const { abortController } = this;

    abortController.abort('Component CardModal will unmount');

    Messenger.off(MessengerEvents.EPISODES_FEED_LOADED, this.loadVodData);

    this.resetTimeRefreshTimer();
    this.resetBroadcastStatusRefreshTimer();

    updateTitle(applicationName);
  }

  initialize = () => {
    const { cardData } = this.props;
    const { isSeries } = this.state;

    if (!cardData) {
      return;
    }

    const {
      item: { id, selectedProgramId, seriesId },
      programMetadata,
      seriesMetadata,
    } = cardData;

    if (programMetadata) {
      this.updateContentType();
    } else {
      this.loadProgramMetadata(selectedProgramId);
    }

    if (isSeries && !seriesMetadata) {
      this.loadSeriesMetadata(seriesId ?? id);
    }
  };

  getProgramMetadata = (): NETGEM_API_V8_METADATA_PROGRAM | null => {
    const { cardData } = this.props;
    const { programMetadata } = this.state;

    return cardData?.programMetadata ?? programMetadata;
  };

  getSeriesMetadata = (): NETGEM_API_V8_METADATA_SERIES | null => {
    const { cardData } = this.props;
    const { seriesMetadata } = this.state;

    return cardData?.seriesMetadata ?? seriesMetadata;
  };

  checkPendingOperation = () => {
    const { cardData, isRegisteredAsGuest } = this.props;

    if (isRegisteredAsGuest || !cardData?.pendingOperation) {
      // User not logged in or no pending operation
      return;
    }

    const {
      pendingOperation: { addToFavoriteData, addToWishlistData, operationType, purchaseData },
    } = cardData;

    /*
     * DEBUG: do not perform pending operation if a subscription was required but nothing has changed
     * // Operation was pending until user logs in
     * if (cardData.pendingOperation?.reason === PendingOperationReason.RequireAccount ||
     * (
     * // Operation was pending until user subscribes
     * cardData.pendingOperation?.reason === PendingOperationReason.RequireSubscription &&
     * Object.values(subscriptions).some((status) => status === SubscriptionStatus.Active || status === SubscriptionStatus.NonRenewing)
     * )) {
     * Messenger.emit(MessengerEvents.OPEN_CARD, cardData);
     * }
     */

    switch (operationType) {
      case PendingOperationKind.AddToFavorite:
        this.performPendingAddToFavorite(addToFavoriteData);
        break;

      case PendingOperationKind.AddToWishlist:
        this.performPendingAddToWishlist(addToWishlistData);
        break;

      case PendingOperationKind.Purchase:
        this.performPendingPurchase(purchaseData);
        break;

      case PendingOperationKind.Play:
        this.performPendingPlay(cardData);
        break;

      case PendingOperationKind.Record:
        // Pending operation is handled at RecordButton level
        break;

      case PendingOperationKind.OpenCard:
        clearPendingOperation();
        break;

      // No default
    }
  };

  performPendingAddToFavorite = (addToFavoriteData: ?ADD_TO_FAVORITE_DATA_TYPE) => {
    const { favoriteList, localAddToFavoriteList } = this.props;

    if (!favoriteList) {
      // Favorite list not retrieved yet
      return;
    }

    clearPendingOperation();

    if (!addToFavoriteData || !addToFavoriteData.titId) {
      // Corrupt data: clear pending operation
      return;
    }

    const { titId } = addToFavoriteData;

    if (favoriteList.includes(titId)) {
      // Item is already in favorite list
      return;
    }

    this.toggleFavoriteInternal(titId, localAddToFavoriteList);
  };

  performPendingAddToWishlist = (addToWishlistData: ?ADD_TO_WISHLIST_DATA_TYPE) => {
    const { localAddToWishlist, wishlist, wishlistStatus } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (wishlistStatus !== LoadableStatus.Loaded) {
      // Wishlist not retrieved yet
      return;
    }

    clearPendingOperation();

    if (!addToWishlistData || !addToWishlistData.assetId || !addToWishlistData.channelId) {
      // Corrupt data: clear pending operation
      return;
    }

    const { assetId, channelId } = addToWishlistData;

    if (wishlist.has(assetId)) {
      // Item is already in Wishlist
      return;
    }

    localAddToWishlist(assetId, channelId, signal).catch((error) => ignoreIfAborted(signal, error));
  };

  performPendingPurchase = (purchaseData: ?VOD_PURCHASE_DATA_TYPE): void => {
    const { purchaseList, showVodPurchaseDialog } = this.props;

    if (!purchaseList) {
      // Purchase list not retrieved yet
      return;
    }

    if (!purchaseData?.vodLocationMetadata?.purchaseInfo || !purchaseData?.vodLocationMetadata?.providerInfo?.distributorId) {
      // Corrupt data: clear pending operation
      clearPendingOperation();
      return;
    }

    const {
      otherVtiId,
      vodLocationMetadata: {
        providerInfo: { distributorId },
        purchaseInfo,
      },
    } = purchaseData;

    // User tried to rent or buy while in guest mode: continue purchase
    const { vtiId } = purchaseInfo;
    if (hasBeenPurchased(purchaseList, distributorId, vtiId, otherVtiId)) {
      // Item already purchased
      clearPendingOperation();
    } else {
      Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, this.vodPurchaseModalClosedCallback);
      showVodPurchaseDialog(purchaseData);
    }
  };

  performPendingPlay = (cardData: CARD_DATA_MODAL_TYPE) => {
    const { distributorId, item, pendingOperation, programMetadata, seriesMetadata, tileType, urlDefinition, viewingHistoryId, vtiId } = cardData;

    clearPendingOperation();

    if (!pendingOperation) {
      // Corrupt data: clear pending operation
      return;
    }

    const { authority, contentType } = pendingOperation;

    if (!authority || !contentType) {
      // Corrupt data: clear pending operation
      return;
    }

    this.checkPlay(authority, contentType, item, programMetadata, seriesMetadata, tileType, distributorId ?? null, viewingHistoryId ?? null, vtiId ?? null, urlDefinition);
  };

  startTimeRefreshTimer = () => {
    this.resetTimeRefreshTimer();
    this.timeRefreshTimer = setTimeout(this.updateTime, REFRESH_TIMEOUT);
  };

  resetTimeRefreshTimer = () => {
    if (this.timeRefreshTimer) {
      clearTimeout(this.timeRefreshTimer);
      this.timeRefreshTimer = null;
    }
  };

  startBroadcastStatusRefreshTimer = () => {
    const { broadcastStatus } = this.state;

    this.resetBroadcastStatusRefreshTimer();

    if (broadcastStatus === BroadcastStatus.Past) {
      // A past item will never change, no need to check again
      return;
    }

    this.broadcastStatusRefreshTimer = setTimeout(this.updateBroadcastStatus, REFRESH_TIMEOUT);
  };

  resetBroadcastStatusRefreshTimer = () => {
    if (this.broadcastStatusRefreshTimer) {
      clearTimeout(this.broadcastStatusRefreshTimer);
      this.broadcastStatusRefreshTimer = null;
    }
  };

  updateTime = () => {
    this.setState({ now: AccurateTimestamp.nowInSeconds() }, this.startTimeRefreshTimer);
  };

  checkDeeplink = () => {
    const { cardData, deeplink, localGetImageSourceUrl } = this.props;

    if (!deeplink || !cardData) {
      return;
    }

    const {
      item: {
        selectedLocation: { duration, previewAvailabilityStartDate },
        type,
      },
    } = cardData;
    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();
    const isSeries = type === ItemType.Series;

    if (!programMetadata || !duration || (isSeries && !seriesMetadata)) {
      return;
    }

    const title = getTitle(seriesMetadata ?? programMetadata, Localizer.language);
    const synopsis = getSynopsis(isSeries ? seriesMetadata : programMetadata, Localizer.language);

    if (title && synopsis) {
      const {
        item: { selectedProgramId, seriesId },
      } = cardData;
      const assetId = isSeries && seriesId ? seriesId : selectedProgramId;

      localGetImageSourceUrl(assetId, VOD_TILE_WIDTH, VOD_TILE_HEIGHT).then((imageUrl) => {
        addLinkedData(deeplink, title, synopsis, duration, imageUrl, previewAvailabilityStartDate);
      });
    }
  };

  updateContentType = () => {
    const { cardData } = this.props;

    if (!cardData) {
      return;
    }

    const {
      item,
      item: { type },
    } = cardData;
    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();
    const { authority } = programMetadata || {};
    const isSeries = type === ItemType.Series;

    if (!programMetadata || (isSeries && !seriesMetadata)) {
      return;
    }

    this.checkDeeplink();

    this.setState(
      {
        authority,
        contentType: getItemContentType(item, authority),
        imageDisplayType: ImageDisplayType.Unset,
        isSeries,
        purchaseInfo: null,
      },
      () => {
        this.updateCard();
        this.loadItemRelatedData();
      },
    );
  };

  updateBroadcastStatus = () => {
    const { cardData } = this.props;
    const { contentType } = this.state;

    if (!cardData) {
      return;
    }

    const {
      item,
      item: { type },
    } = cardData;

    // Item is an SVOD but is displayed as a catchup (e.g. Okoo)
    this.setState({ broadcastStatus: contentType === ItemContent.NetgemSVOD && type !== ItemType.Series ? BroadcastStatus.Past : getBroadcastStatus(item) }, this.startBroadcastStatusRefreshTimer);
  };

  updateFollowedPictoClassName = (prevIsFollowed: ?boolean, isFollowed: ?boolean) => {
    let followedPictoClassName = '';

    if (prevIsFollowed === null) {
      // First load of card
      followedPictoClassName = isFollowed ? 'visible' : '';
    } else {
      // Card update
      followedPictoClassName = isFollowed ? 'appearing' : 'disappearing';
    }

    this.setState({ followedPictoClassName });
  };

  updateIsFollowed = () => {
    const { props, state } = this;
    this.setState({ isFollowed: isFavorite(props, state) });
  };

  updateCard = () => {
    const { applicationName, cardData, isNpvrEnabled, npvrRecordingsFuture, npvrRecordingsList, npvrScheduledRecordingsList } = this.props;
    const { broadcastStatus, contentType, isSeries, previewCatchupScheduledEventId } = this.state;

    if (!cardData) {
      return;
    }

    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();
    const {
      item,
      item: {
        locType,
        selectedLocation: { channelId },
      },
    } = cardData;
    const programId = programMetadata?.id ?? '';

    updateTitle(applicationName, getTitle(seriesMetadata ?? programMetadata, Localizer.language));

    const { failedRecordingId, hasRecording, hasScheduledRecording, hasSeriesScheduledRecording, recordOutcome, warningScheduledEventId, warningScheduledRecordingId } =
      getEpisodeAndSeriesRecordStatus(programId, seriesMetadata?.id, item, npvrRecordingsList, npvrRecordingsFuture, npvrScheduledRecordingsList, previewCatchupScheduledEventId);

    const scheduledRecordingId = warningScheduledRecordingId || (failedRecordingId ? getScheduledRecordingIdFromRecordingId(failedRecordingId, npvrScheduledRecordingsList) : null);

    const isDeletable =
      isNpvrEnabled &&
      (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) &&
      (hasRecording || hasScheduledRecording || hasSeriesScheduledRecording);

    this.recordOutcome = recordOutcome;
    this.failedRecordingId = failedRecordingId;
    this.warningScheduledRecordingId = scheduledRecordingId;
    this.warningScheduledEventId = warningScheduledEventId;

    this.updateChannelImage(channelId);

    this.setState(
      {
        canBePlayed: isItemPlayable(cardData, broadcastStatus, contentType, isSeries, recordOutcome),
        isDeletable,
        isLiveRecording: hasRecording && broadcastStatus === BroadcastStatus.Live,
      },
      this.updateIsFollowed,
    );
  };

  updateChannelImage = (channelId?: string) => {
    const { channels, localGetImageUrl } = this.props;
    const {
      abortController: { signal },
    } = this;

    const channelImageId = getChannelImageId(channels, channelId);

    if (!channelImageId) {
      return;
    }

    localGetImageUrl(channelImageId, CHANNEL_IMAGE_WIDTH, CHANNEL_IMAGE_HEIGHT, Theme.Dark, signal)
      .then((channelImageUrl: string) => {
        signal.throwIfAborted();

        this.setState({ channelImageUrl });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadPurchaseInfo = () => {
    const { cardData, localGetPurchaseInfoPerAsset } = this.props;
    const { contentType } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (!cardData) {
      return;
    }

    const {
      item: {
        id,
        purchasable,
        selectedLocation: { channelId },
      },
    } = cardData;

    if ((contentType !== ItemContent.VODOrDeeplink && contentType !== ItemContent.NetgemSVOD) || !purchasable) {
      return;
    }

    if (typeof channelId === 'undefined') {
      logError(`Channel Id is undefined for item ${id}`);
      return;
    }

    localGetPurchaseInfoPerAsset(id, channelId, signal)
      .then((purchaseInfo: NETGEM_API_V8_PURCHASE_INFO) => {
        signal.throwIfAborted();
        this.setState({ purchaseInfo });
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ purchaseInfo: null })));
  };

  loadProgramMetadata = (programId: string) => {
    const { localSendV8MetadataRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8MetadataRequest(programId, METADATA_KIND_PROGRAM, signal)
      .then((metadata: NETGEM_API_V8_METADATA) => {
        signal.throwIfAborted();

        const programMetadata = ((filterCredits(metadata): any): NETGEM_API_V8_METADATA_PROGRAM);
        this.setState({ programMetadata }, this.updateContentType);
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadSeriesMetadata = (seriesId: string) => {
    const { localSendV8MetadataRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8MetadataRequest(seriesId, METADATA_KIND_SERIES, signal)
      .then((metadata: NETGEM_API_V8_METADATA) => {
        signal.throwIfAborted();

        const seriesMetadata = ((filterCredits(metadata): any): NETGEM_API_V8_METADATA_SERIES);
        this.setState({ seriesMetadata }, this.updateContentType);
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ seriesMetadata: null })));
  };

  loadItemRelatedData = () => {
    const { cardData, usePackPurchaseApi } = this.props;
    const { isSeries } = this.state;

    if (!cardData) {
      return;
    }

    const { item } = cardData;
    const isVod = isVodItem(item);

    if (isVod) {
      // TODO: clean up code when BO API v2 is fully supported
      if (usePackPurchaseApi) {
        this.loadPurchaseInfo();
      }

      if (!isSeries) {
        this.loadVodData();
      }
    } else {
      this.loadTVLocationMetadata(item);
    }

    this.loadLandscapeImage(isVod);
    this.loadStartoverItem(cardData);
  };

  loadTVLocationMetadata = (item: NETGEM_API_V8_FEED_ITEM) => {
    const { cardData, localSendV8MetadataLocationRequest, localSendV8RecordingsMetadataRequest } = this.props;
    const { authority, broadcastStatus } = this.state;
    const {
      abortController: { signal },
    } = this;
    const {
      locType,
      selectedLocation: { id },
    } = item;

    if (!cardData || !id) {
      return;
    }

    const locationStatus = getLocationStatus(item, authority);
    const request =
      locationStatus === WebAppHelpersLocationStatus.Recording || (locationStatus === WebAppHelpersLocationStatus.Live && locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING)
        ? localSendV8RecordingsMetadataRequest
        : localSendV8MetadataLocationRequest;

    request(id, signal)
      .then((requestResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
        signal.throwIfAborted();

        const {
          result,
          result: {
            location: { target },
          },
        } = requestResponse;
        this.setState(
          {
            previewCatchupScheduledEventId: broadcastStatus === BroadcastStatus.Preview ? target : null,
            tvLocationMetadata: result,
          },
          this.checkPendingOperation,
        );
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  handleInfoOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    const { isDebugModeEnabled } = this.props;

    if (isDebugModeEnabled && event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();

      const { props, state } = this;
      showDebug('Card', {
        instance: this,
        instanceFields: ['failedRecordingId', 'recordOutcome', 'warningScheduledEventId', 'warningScheduledRecordingId'],
        props,
        propsFields: ['cardData'],
        state,
        stateFields: [
          'authority',
          'backgroundImageUrl',
          'broadcastStatus',
          'canBePlayed',
          'channelImageUrl',
          'contentType',
          'followedPictoClassName',
          'imageDisplayType',
          'isDeletable',
          'isFollowed',
          'isLiveRecording',
          'isSeries',
          'isWaitingForDeleteConfirmation',
          'isWaitingForRecordConfirmation',
          'now',
          'portraitImageUrl',
          'previewCatchupScheduledEventId',
          'programMetadata',
          'purchaseInfo',
          'seriesMetadata',
          'startoverItem',
          'tvLocationMetadata',
          'vodLocationsMetadata',
          'vodStatus',
        ],
      });
    }
  };

  getImageDisplayType = (isVod: boolean, imageId: string | null): ImageDisplayType => {
    // Special FranceChannel case
    if (parseBoolean(process.env.REACT_APP_FORCE_LANDSCAPE_VOD_CARD)) {
      return ImageDisplayType.Landscape;
    }

    return imageId || !isVod ? ImageDisplayType.Landscape : ImageDisplayType.Portrait;
  };

  loadLandscapeImage = (isVod: boolean) => {
    const { cardData } = this.props;
    const { isSeries } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (!cardData) {
      return;
    }

    let imageId: string | null = null;

    if (isVod && !parseBoolean(process.env.REACT_APP_FORCE_LANDSCAPE_VOD_CARD)) {
      const {
        item: { seriesId },
      } = cardData;
      const programMetadata = this.getProgramMetadata();
      const seriesMetadata = this.getSeriesMetadata();
      const imagesMetadata = isSeries && seriesId ? seriesMetadata?.images : programMetadata?.images;

      if (imagesMetadata) {
        imageId = findLandscapeImageId(imagesMetadata, IMAGE_TAG_NO_TEXT);
      }

      if (!imageId) {
        // If landscape image without text is not found, let's load portrait image
        this.loadPortraitImage();
        return;
      }
    }

    this.loadImage(CARD_BACKGROUND_IMAGE_WIDTH, CARD_BACKGROUND_IMAGE_HEIGHT, signal, imageId)
      .then((imageUrl) => {
        // Image is set to background image whatever its orientation because if it's portrait, it will be blurred
        this.setState({
          backgroundImageUrl: imageUrl,
          imageDisplayType: this.getImageDisplayType(isVod, imageId),
        });
      })
      .catch(() => {
        if (signal.aborted) {
          return;
        }
        this.setState({ backgroundImageUrl: null });
      });
  };

  loadPortraitImage = () => {
    const {
      abortController: { signal },
    } = this;

    this.loadImage(VOD_TILE_WIDTH, VOD_TILE_HEIGHT, signal)
      .then((portraitImageUrl) =>
        this.setState({
          backgroundImageUrl: portraitImageUrl,
          imageDisplayType: ImageDisplayType.Portrait,
          portraitImageUrl,
        }),
      )
      .catch(() => {
        if (signal.aborted) {
          return;
        }
        this.setState({ portraitImageUrl: null });
      });
  };

  loadImage = (width: number, height: number, signal: AbortSignal, imageId: ?string): Promise<?string> => {
    const { cardData, localGetImageUrl } = this.props;
    const { isSeries } = this.state;

    if (!cardData) {
      return Promise.reject(new Error('No cardData'));
    }

    const {
      item: { selectedProgramId, seriesId },
    } = cardData;
    const assetId = isSeries && seriesId ? seriesId : selectedProgramId;

    return localGetImageUrl(imageId ?? assetId, width, height, undefined, signal).then((response) => {
      signal.throwIfAborted();

      return response;
    });
  };

  loadStartoverItem = (cardData: CARD_DATA_MODAL_TYPE) => {
    const { channels, localSendV8LocationCatchupForAssetRequest } = this.props;
    const {
      abortController: { signal },
    } = this;
    const {
      item,
      item: { locType, selectedLocation, selectedProgramId },
    } = cardData;

    if (!selectedLocation) {
      this.setState({ startoverItem: null });
      return;
    }

    const { channelId } = selectedLocation;

    if (!channelHasStartover(channels, channelId) || locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT || !isItemLiveOrAboutToStart(item)) {
      this.setState({ startoverItem: null });
      return;
    }

    localSendV8LocationCatchupForAssetRequest(selectedProgramId, AccurateTimestamp.now(), MILLISECONDS_PER_HOUR, signal)
      .then((requestResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
        signal.throwIfAborted();

        const startoverItem: NETGEM_API_V8_FEED_ITEM | null = getStartOverItem(item, requestResponse);
        this.setState({ startoverItem });
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ startoverItem: null })));
  };

  loadVodData = () => {
    const { cardData, localGetVodLocations } = this.props;
    const { isSeries } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (!cardData) {
      return;
    }

    const {
      item,
      item: { elements, locations },
    } = cardData;

    if (!isVodItem(item)) {
      return;
    }

    if (!locations) {
      if (isSeries && elements) {
        this.loadEpisodesVodData(elements);
      }
      return;
    }

    // VOD program
    localGetVodLocations(locations, signal)
      .then((vodLocationsMetadata) => {
        signal.throwIfAborted();

        this.setState({ vodLocationsMetadata }, () => {
          this.updateIsFollowed();
          this.checkPendingOperation();
        });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadEpisodesVodData = (elements: NETGEM_API_V8_RAW_FEED) => {
    const { localGetVodLocations } = this.props;
    const {
      abortController: { signal },
    } = this;

    const promises = [];
    for (let i = 0; i < elements.length; ++i) {
      const { locations } = elements[i];
      if (locations) {
        promises.push(localGetVodLocations(locations, signal));
      }
    }

    Promise.all(promises)
      .then((results) => {
        if (signal.aborted) {
          return;
        }

        const locationsArray = ((results: any): Array<Array<NETGEM_API_V8_METADATA_SCHEDULE>>);
        const vodLocationsMetadata: Array<NETGEM_API_V8_METADATA_SCHEDULE> = [];
        locationsArray.forEach((loc) => vodLocationsMetadata.push(...loc));
        this.setState({ vodLocationsMetadata }, this.checkPendingOperation);
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  updateVodStatus = () => {
    const { cardData, purchaseList } = this.props;
    const { isSeries, vodLocationsMetadata } = this.state;

    if (!cardData) {
      return;
    }

    const {
      item,
      item: { locType },
    } = cardData;
    if (!isVodItem(item)) {
      return;
    }

    if (!isSeries) {
      this.setState({ vodStatus: getVodStatusFromLocations(vodLocationsMetadata, purchaseList) });
    } else if (locType === (VodKind.SVOD: string)) {
      // SVOD series: display message at series level
      this.setState({ vodStatus: { status: BOVodAssetStatus.Free } });
    }
  };

  handlePlayButtonOnClick = () => {
    const { cardData } = this.props;
    const { authority, contentType, purchaseInfo, vodLocationsMetadata, vodStatus } = this.state;

    if (!cardData) {
      return;
    }

    const { item, tileType, urlDefinition } = cardData;
    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();
    const distributorId = getDistributorId(vodLocationsMetadata, purchaseInfo, programMetadata);

    this.checkPlay(authority, contentType, item, programMetadata, seriesMetadata, tileType, distributorId, vodStatus?.viewingHistoryId ?? null, vodStatus?.vtiId ?? null, urlDefinition);
  };

  checkPlay = (
    authority?: NETGEM_API_V8_AUTHENT_REALM,
    contentType: ItemContent,
    item: NETGEM_API_V8_FEED_ITEM,
    programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
    seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
    tileType: TileConfigTileType,
    distributorId: string | null,
    viewingHistoryId: string | null,
    vtiId: number | null,
    urlDefinition?: NETGEM_API_V8_URL_DEFINITION | null,
  ) => {
    const { channels, commercialOffers, defaultRights, isRegisteredAsGuest, isSubscriptionFeatureEnabled, localHideModal, userRights } = this.props;
    const { previewCatchupScheduledEventId, tvLocationMetadata, vodLocationsMetadata } = this.state;

    const locationMetadata = tvLocationMetadata ?? getVodLocationFromVtiId(vodLocationsMetadata, vtiId);

    const cardDataLocal = {
      distributorId,
      item,
      programMetadata,
      seriesMetadata,
      tileType,
      urlDefinition,
      viewingHistoryId,
      vtiId,
    };

    if (
      !isPlaybackGranted(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardDataLocal, authority, contentType, locationMetadata?.location, channels, commercialOffers, defaultRights, userRights)
    ) {
      // Playback denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    if (contentType === ItemContent.VODOrDeeplink) {
      if (vtiId) {
        Messenger.emit(MessengerEvents.OPEN_PLAYER, {
          distributorId,
          item,
          locationId: locationMetadata?.location.id,
          programMetadata,
          seriesMetadata,
          tileType,
          type: ExtendedItemType.VOD,
          viewingHistoryId,
          vtiId,
        });
      }
    } else {
      Messenger.emit(MessengerEvents.OPEN_PLAYER, {
        authority,
        item,
        previewCatchupScheduledEventId,
        programMetadata,
        seriesMetadata,
        type: ExtendedItemType.TV,
      });
    }
  };

  handleTrailerButtonOnClick = () => {
    const { cardData } = this.props;

    if (!cardData) {
      return;
    }

    Messenger.emit(MessengerEvents.OPEN_PLAYER, {
      programMetadata: this.getProgramMetadata(),
      seriesMetadata: this.getSeriesMetadata(),
      type: ExtendedItemType.Trailer,
    });
  };

  handleReplayButtonOnClick = () => {
    const { cardData } = this.props;
    const { startoverItem } = this.state;

    if (cardData && startoverItem) {
      const programMetadata = this.getProgramMetadata();
      const seriesMetadata = this.getSeriesMetadata();

      Messenger.emit(MessengerEvents.OPEN_PLAYER, {
        item: startoverItem,
        programMetadata,
        seriesMetadata,
        type: ExtendedItemType.TV,
      });
    }
  };

  handleAddToWishlistButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { cardData, localAddToWishlist, localAddToFavoriteList } = this.props;
    const { authority } = this.state;

    if (!cardData) {
      return;
    }

    if (authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
      this.toggleFavorite(cardData, localAddToFavoriteList);
    } else {
      this.toggleWishlist(cardData, localAddToWishlist);
    }
  };

  handleRemoveFromWishlistButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { cardData, localRemoveFromWishlist, localRemoveFromFavoriteList } = this.props;
    const { authority } = this.state;

    if (!cardData) {
      return;
    }

    if (authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
      this.toggleFavorite(cardData, localRemoveFromFavoriteList);
    } else {
      this.toggleWishlist(cardData, localRemoveFromWishlist);
    }
  };

  toggleWishlist = (cardData: CARD_DATA_MODAL_TYPE, toggler: (assetId: string, channelId: string, signal?: AbortSignal) => Promise<any>) => {
    const { channels, commercialOffers, defaultRights, isRegisteredAsGuest, isSubscriptionFeatureEnabled, localHideModal, userRights } = this.props;
    const { tvLocationMetadata, vodLocationsMetadata, vodStatus } = this.state;
    const {
      abortController: { signal },
    } = this;

    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();

    const {
      item: {
        selectedLocation: { channelId },
        selectedProgramId,
      },
    } = cardData;
    const assetId = seriesMetadata?.id ?? selectedProgramId;

    if (!channelId) {
      // Channel Id is null
      return;
    }

    const locationMetadata = tvLocationMetadata ?? getVodLocationFromVtiId(vodLocationsMetadata, vodStatus?.vtiId);

    const cardDataLocal = {
      ...cardData,
      programMetadata,
      seriesMetadata,
    };

    if (!isWishlistGranted(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardDataLocal, locationMetadata?.location, channels, commercialOffers, defaultRights, userRights)) {
      // Add to wishlist denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    toggler(assetId, channelId, signal).catch((error) => ignoreIfAborted(signal, error));
  };

  toggleFavoriteInternal = (titId: string, toggler: (titId: string, signal?: AbortSignal) => Promise<any>) => {
    const {
      abortController: { signal },
    } = this;

    toggler(titId, signal).catch((error) => ignoreIfAborted(signal, error));
  };

  toggleFavorite = (cardData: CARD_DATA_MODAL_TYPE, toggler: (titId: string, signal?: AbortSignal) => Promise<any>) => {
    const { channels, commercialOffers, defaultRights, isRegisteredAsGuest, isSubscriptionFeatureEnabled, localHideModal, userRights } = this.props;
    const { vodLocationsMetadata, vodStatus } = this.state;

    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();

    if (!programMetadata) {
      return;
    }

    const { providerInfo } = programMetadata;

    if (!providerInfo) {
      return;
    }

    const locationMetadata = getVodLocationFromVtiId(vodLocationsMetadata, vodStatus?.vtiId);
    const cardDataLocal = {
      ...cardData,
      programMetadata,
      seriesMetadata,
    };

    const titId = getTitIdFromProviderInfo(providerInfo, seriesMetadata);

    if (!isFavoriteGranted(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardDataLocal, titId, locationMetadata?.location, channels, commercialOffers, defaultRights, userRights)) {
      // Add to favorite list denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    this.toggleFavoriteInternal(titId, toggler);
  };

  vodPurchaseModalClosedCallback = (data: any): void => {
    const { cardData } = this.props;
    const { purchaseInfo, vodLocationsMetadata } = this.state;

    if (!data) {
      // Purchase cancelled
      return;
    }

    const { locationId, playNow, viewingHistoryId, vtiId } = data;

    if (!cardData || !playNow || !vtiId) {
      return;
    }

    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();

    const { item } = cardData;

    Messenger.emit(MessengerEvents.OPEN_PLAYER, {
      distributorId: getDistributorId(vodLocationsMetadata, purchaseInfo, programMetadata),
      item,
      locationId,
      programMetadata,
      seriesMetadata,
      type: ExtendedItemType.VOD,
      viewingHistoryId,
      vtiId,
    });
  };

  handlePurchaseButtonOnClick = (data: BASE_VOD_PURCHASE_DATA_TYPE): void => {
    const { cardData, isRegisteredAsGuest, localHideModal, showVodPurchaseDialog } = this.props;

    const { definition, displayPrice, distributorId, itemCount, licenseDuration, locationId, otherVtiId, purchaseType, vodLocationMetadata, vtiId } = data;

    if (!cardData) {
      return;
    }

    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();

    if (!programMetadata) {
      return;
    }

    const providerInfo = vodLocationMetadata?.providerInfo ?? programMetadata.providerInfo;
    const { item } = cardData;

    const purchaseData: VOD_PURCHASE_DATA_TYPE = {
      definition,
      displayPrice,
      distributorId,
      item,
      itemCount,
      licenseDuration,
      locationId,
      otherVtiId,
      programMetadata,
      purchaseType,
      seriesMetadata,
      viewingHistoryId: providerInfo?.viewingHistoryId,
      vodLocationMetadata,
      vtiId,
    };

    if (isRegisteredAsGuest) {
      // Guest mode: let's open the sign-in page
      storePendingOperation({
        ...cardData,
        pendingOperation: {
          operationType: PendingOperationKind.Purchase,
          purchaseData,
          reason: PendingOperationReason.RequireAccount,
        },
      });
      localHideModal();
      Messenger.emit(MessengerEvents.SHOW_SIGN_IN, PendingOperationKind.Purchase);
    } else {
      Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, this.vodPurchaseModalClosedCallback);
      showVodPurchaseDialog(purchaseData);
    }
  };

  openPreviousCard = () => {
    const { cardData } = this.props;

    if (!cardData || !cardData.previousCard) {
      return;
    }

    const { previousCard } = cardData;
    Messenger.emit(MessengerEvents.OPEN_CARD, previousCard);
  };

  renderChannelImage = (): React.Node => {
    const { channelImageUrl } = this.state;

    if (!channelImageUrl) {
      return null;
    }

    return <div className='channelImage' style={{ backgroundImage: `url(${channelImageUrl})` }} />;
  };

  renderTitleAndSynopsis = (cardData: CARD_DATA_MODAL_TYPE, title: ?string): {| synopsisElement: React.Node, titleElement: React.Node |} => {
    const { followedPictoClassName, isSeries } = this.state;

    const { isOpenFromPlayer } = cardData;
    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();

    const synopsisElement = <div className='synopsis'>{getSynopsis(isSeries ? seriesMetadata : programMetadata, Localizer.language)}</div>;

    const titleElement = (
      <div className='text'>
        <PictoHeartFull className={clsx('fullHeart', followedPictoClassName)} />
        <div className='title'>{seriesMetadata && (!isOpenFromPlayer || !title) ? getTitle(seriesMetadata, Localizer.language) : title}</div>
      </div>
    );

    return {
      synopsisElement,
      titleElement,
    };
  };

  renderBroadcastTime = (now: number, startTime: number, endTime: number): React.Node => {
    const { cardData } = this.props;
    const { contentType, isSeries } = this.state;

    if (!cardData) {
      return null;
    }

    const {
      item: { locType, selectedLocation },
    } = cardData;
    const { availabilityEndDate } = selectedLocation || {};
    const details = [];

    if ((locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) && availabilityEndDate) {
      details.push(
        <div className='date expiration' key='expirationDate'>
          {formatExpirationDate(availabilityEndDate)}
        </div>,
      );
    }

    if (!isSeries && contentType === ItemContent.TV) {
      details.push(
        <div className='date broadcast' key='broadcastDate'>
          {formatDate(startTime, endTime, now)}
        </div>,
      );
    }

    return <>{details.map((d) => d)}</>;
  };

  renderProgramOrSeriesInfo = (isVodOrDeeplink: boolean): Array<React.Node> | null => {
    const { cardData } = this.props;
    const { isSeries, vodLocationsMetadata } = this.state;

    if (!cardData) {
      return null;
    }

    const { item } = cardData;
    const seriesMetadata = this.getSeriesMetadata();
    const metadata = isSeries && seriesMetadata ? seriesMetadata : this.getProgramMetadata();

    /* eslint-disable no-bitwise */
    let programInfo = PROGRAM_INFO.ParentalGuidance | PROGRAM_INFO.ReleaseDate | PROGRAM_INFO.Language | PROGRAM_INFO.Genre | PROGRAM_INFO.ProductionCountry;
    if (!isSeries && (!isVodOrDeeplink || !seriesMetadata)) {
      // Duration should also been displayed
      programInfo |= PROGRAM_INFO.Duration;
    }
    /* eslint-enable no-bitwise */

    return renderProgramDetails(item, metadata, vodLocationsMetadata, programInfo);
  };

  renderVodActionPanel = (): React.Node => {
    const { cardData, streamPriorities } = this.props;
    const { isSeries, purchaseInfo, vodLocationsMetadata, vodStatus } = this.state;

    if (!cardData) {
      return null;
    }

    const {
      isOpenFromPlayer,
      item: { locType },
    } = cardData;

    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();

    const status = vodStatus?.status ?? BOVodAssetStatus.Unknown;
    const expirationTime = vodStatus?.expirationTime ?? 0;

    const isPlayButtonDisplayed =
      !isOpenFromPlayer && !isSeries && (status === BOVodAssetStatus.Free || status === BOVodAssetStatus.Rented || status === BOVodAssetStatus.Bought || locType === (VodKind.SVOD: string));

    const progress = getWatchingStatus(this.props, this.state);

    return (
      <>
        <PricingVod
          context={PricingContext.Card}
          expirationTime={expirationTime}
          isVodSeries={isSeries}
          locationsMetadata={vodLocationsMetadata}
          onClick={this.handlePurchaseButtonOnClick}
          purchaseInfo={purchaseInfo}
          status={status}
        />
        {isPlayButtonDisplayed ? (
          <ButtonFX allowZeroProgress={false} key='play' onClick={this.handlePlayButtonOnClick} progress={progress}>
            {Localizer.localize('common.actions.watch')}
          </ButtonFX>
        ) : null}
        {!isOpenFromPlayer && hasTrailer(streamPriorities, programMetadata, seriesMetadata) ? (
          <ButtonFX key='trailer' onClick={this.handleTrailerButtonOnClick}>
            {Localizer.localize('modal.card.actions.trailer')}
          </ButtonFX>
        ) : null}
        {this.renderWishlistButton()}
      </>
    );
  };

  renderWishlistButton = (): React.Node => {
    const { isFollowed } = this.state;

    if (isFollowed) {
      return (
        <ButtonFX key='follow' onClick={this.handleRemoveFromWishlistButtonOnClick}>
          {Localizer.localize('modal.card.actions.remove_from_favorites')}
        </ButtonFX>
      );
    }

    return (
      <ButtonFX key='follow' onClick={this.handleAddToWishlistButtonOnClick}>
        {Localizer.localize('modal.card.actions.add_to_favorites')}
      </ButtonFX>
    );
  };

  renderTVActionPanel = (): React.Node => {
    const { cardData, streamPriorities } = this.props;
    const { broadcastStatus, canBePlayed, isSeries, now, previewCatchupScheduledEventId, startoverItem, tvLocationMetadata } = this.state;

    if (!cardData) {
      return null;
    }

    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();

    const {
      isOpenFromPlayer,
      isTimeshifting,
      item,
      item: { locType },
      tileType,
      urlDefinition,
    } = cardData;
    const progress = broadcastStatus === BroadcastStatus.Live ? getLiveProgress(cardData, now) : getWatchingStatus(this.props, this.state);

    // Recording a program is not allowed while timeshifting
    const isRecordingAllowed =
      (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) &&
      (isSeries || isTimeshifting !== true);

    // Deduce record king from location type, then from card type (series Vs. single)
    let recordKind: Undefined<NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND> = undefined;
    if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) {
      recordKind = NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY;
    } else if (isSeries) {
      recordKind = NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES;
    }

    return (
      <>
        {canBePlayed ? (
          <ButtonFX allowZeroProgress={broadcastStatus === BroadcastStatus.Live} key='play' onClick={this.handlePlayButtonOnClick} progress={progress}>
            {Localizer.localize(broadcastStatus === BroadcastStatus.Live && locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING ? 'common.actions.watch_live' : 'common.actions.watch')}
          </ButtonFX>
        ) : null}
        {!isOpenFromPlayer && hasTrailer(streamPriorities, programMetadata, seriesMetadata) ? (
          <ButtonFX key='trailer' onClick={this.handleTrailerButtonOnClick}>
            {Localizer.localize('modal.card.actions.trailer')}
          </ButtonFX>
        ) : null}
        {canBePlayed && broadcastStatus === BroadcastStatus.Live && startoverItem ? (
          <ButtonFX key='start_over' onClick={this.handleReplayButtonOnClick}>
            {Localizer.localize('modal.card.actions.start_over')}
          </ButtonFX>
        ) : null}
        {isRecordingAllowed ? (
          <RecordButton
            broadcastStatus={broadcastStatus}
            cardDataUrlDefinition={urlDefinition ?? undefined}
            context={RecordContext.Card}
            displayMode={DisplayMode.Button}
            item={item}
            key='record'
            kind={recordKind}
            location={tvLocationMetadata?.location ?? null}
            previewCatchupScheduledEventId={previewCatchupScheduledEventId}
            programMetadata={programMetadata}
            seriesMetadata={seriesMetadata}
            tileType={tileType}
          />
        ) : null}
        {this.renderWishlistButton()}
      </>
    );
  };

  renderActionPanelAndPricing = (): React.Node => {
    const { cardData } = this.props;
    const { contentType } = this.state;

    if (!cardData) {
      return null;
    }

    return contentType === ItemContent.VODOrDeeplink ? this.renderVodActionPanel() : this.renderTVActionPanel();
  };

  getBackTitle = (previousCard: CARD_DATA_MODAL_TYPE): ?string => {
    const {
      item: { type },
      programMetadata,
      seriesMetadata,
    } = previousCard;

    const seriesTitle = getTitle(seriesMetadata, Localizer.language);
    const { episodeIndex, episodeTitle } = getEpisodeIndexAndTitle(programMetadata, seriesTitle, Localizer.language);

    const titleArray = [];
    if (seriesTitle) {
      titleArray.push(seriesTitle);
    }
    if (type !== ItemType.Series && episodeTitle) {
      titleArray.push(`${episodeIndex ? `${episodeIndex} : ` : ''}${episodeTitle}`);
    }

    return titleArray.length > 0 ? titleArray.join(' - ') : null;
  };

  renderTagline = (cardData: CARD_DATA_MODAL_TYPE, title: string | null): React.Node => {
    const { isSeries } = this.state;

    const metadata = isSeries ? this.getSeriesMetadata() : this.getProgramMetadata();
    const tagline = getTagline(metadata, Localizer.language);

    if (!tagline || tagline === title) {
      return null;
    }

    return <div className='tagline'>{tagline}</div>;
  };

  handleErrorPictoOnClick = () => {
    const { failedRecordingId, warningScheduledRecordingId, warningScheduledEventId } = this;

    Messenger.emit(MessengerEvents.OPEN_NPVR_MANAGEMENT_SCREEN, warningScheduledRecordingId, failedRecordingId, warningScheduledEventId);
  };

  renderRecordErrorPicto = (recordOutcome: ?RecordingOutcome, broadcastStatus: BroadcastStatus): React.Node => {
    const { cardData } = this.props;

    if (!cardData || !recordOutcome) {
      return null;
    }

    let description: string | null = null;
    if (recordOutcome === RecordingOutcome.OutOfQuota) {
      description = Localizer.localize('modal.card.status_picto.warning_out_of_quota');
    } else if (recordOutcome === RecordingOutcome.ExceededConcurrency) {
      description = Localizer.localize('modal.card.status_picto.warning_exceeded_concurrency');
    } else if (recordOutcome === RecordingOutcome.ServerError) {
      description = Localizer.localize('modal.card.status_picto.warning_server');
    } else {
      return null;
    }

    let pictoElement = null;
    if (broadcastStatus === BroadcastStatus.Past || broadcastStatus === BroadcastStatus.Live) {
      description = Localizer.localize('modal.card.status_picto.failure');
      pictoElement = <PictoFailure className='alertPicto failure' />;
    } else {
      pictoElement = <PictoWarning className='alertPicto warning' />;
    }

    return (
      <div className='recordError' onClick={this.handleErrorPictoOnClick}>
        {pictoElement}
        <div className='description'>{description}</div>
      </div>
    );
  };

  renderSingleTVProgramInfo = (item: NETGEM_API_V8_FEED_ITEM): React.Node => {
    const { broadcastStatus, contentType, isDeletable, isLiveRecording, isSeries } = this.state;
    const { recordOutcome } = this;

    if (isSeries || contentType !== ItemContent.TV) {
      return null;
    }

    const liveRecordingElement = isLiveRecording ? (
      <div className='liveRecording'>
        <PictoRecord className='recording live' />
        <div className='text'>{Localizer.localize('common.status.live_recording')}</div>
      </div>
    ) : null;

    const recordErrorPicto = this.renderRecordErrorPicto(recordOutcome, broadcastStatus);

    const scheduledRecordingElement =
      !recordErrorPicto && !isLiveRecording && isDeletable && broadcastStatus === BroadcastStatus.Future ? (
        <div className='scheduledRecording'>
          <PictoClock className='scheduledRecording' hasBackground />
          <div className='text'>{Localizer.localize('common.status.scheduled_recording')}</div>
        </div>
      ) : null;

    const { endTime, startTime } = getStartAndEndTimes(item);

    return (
      <div className='programInfo'>
        <StatusPicto item={item} />
        {liveRecordingElement}
        {scheduledRecordingElement}
        {recordErrorPicto}
        {this.renderBroadcastTime(AccurateTimestamp.nowInSeconds(), startTime, endTime)}
      </div>
    );
  };

  renderChildren = (cardData: CARD_DATA_MODAL_TYPE): React.Node => {
    const { backgroundImageUrl, contentType, imageDisplayType, isSeries, portraitImageUrl } = this.state;

    const { displayedSectionCount, item } = cardData;
    const programMetadata = this.getProgramMetadata();
    const seriesMetadata = this.getSeriesMetadata();
    const channelImage = this.renderChannelImage();
    const title = getTitle(programMetadata, Localizer.language);
    const { synopsisElement, titleElement } = this.renderTitleAndSynopsis(cardData, title);
    const programInfoElement = this.renderProgramOrSeriesInfo(contentType === ItemContent.VODOrDeeplink);
    const singleTVProgramInfoElement = this.renderSingleTVProgramInfo(item);
    const actionPanel = this.renderActionPanelAndPricing();
    const tagLineElement = contentType === ItemContent.VODOrDeeplink ? this.renderTagline(cardData, title) : null;

    const backgroundElement =
      imageDisplayType !== ImageDisplayType.Unset && backgroundImageUrl ? (
        <>
          <div className={clsx('backgroundImage', 'image', imageDisplayType === ImageDisplayType.Portrait && 'blurred')} style={{ backgroundImage: `url(${backgroundImageUrl})` }} />
          <div className='backgroundImage mask' />
        </>
      ) : null;

    const coverElement =
      imageDisplayType !== ImageDisplayType.Unset && imageDisplayType === ImageDisplayType.Portrait && portraitImageUrl ? (
        <img alt='' className='cover' onClick={this.handleInfoOnClick} src={portraitImageUrl} />
      ) : null;

    const avenueElement = item ? <CardAvenue cardData={cardData} displayedSectionCount={displayedSectionCount} key={item.id} /> : null;

    return (
      <div
        className={clsx('slider', (typeof displayedSectionCount !== 'number' || displayedSectionCount > 1) && 'parallax')}
        ref={(instance) => {
          this.slider = instance;
        }}
      >
        {backgroundElement}
        {coverElement}
        <div className='infoAndActionsContainer' onClick={this.handleInfoOnClick}>
          <div className='infoContainer'>
            {channelImage}
            {titleElement}
            <div className='metadataContainer'>
              {singleTVProgramInfoElement}
              <div className='programInfo'>{programInfoElement}</div>
              <CreditsView metadata={isSeries && seriesMetadata ? seriesMetadata : programMetadata} />
              {tagLineElement}
              {synopsisElement}
            </div>
          </div>
          <div className='iconBar'>{actionPanel}</div>
        </div>
        {avenueElement}
      </div>
    );
  };

  render(): React.Node {
    const { cardData, index } = this.props;
    const { imageDisplayType } = this.state;

    if (!cardData) {
      return null;
    }

    const { previousCard, tileType } = cardData;

    return (
      <Modal
        backCallback={previousCard ? this.openPreviousCard : undefined}
        className={clsx('card', getDisplayType(imageDisplayType, tileType))}
        index={index}
        title={previousCard ? this.getBackTitle(previousCard) : undefined}
      >
        {this.renderChildren(cardData)}
      </Modal>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxCardModalReducerStateType => {
  return {
    applicationName: state.appConfiguration.applicationName,
    channels: state.appConfiguration.deviceChannels,
    commercialOffers: state.appConfiguration.rightsCommercialOffers,
    deeplink: state.ui.deeplink,
    defaultRights: state.appConfiguration.rightsDefault,
    favoriteList: state.ui.favoriteList || [],
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isNpvrEnabled: state.appConfiguration.features[FEATURE_NPVR] && state.npvr.isEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    npvrRecordingsFuture: state.npvr.npvrRecordingsFuture,
    npvrRecordingsList: state.npvr.npvrRecordingsList,
    npvrScheduledRecordingsList: state.npvr.npvrScheduledRecordingsList,
    purchaseList: state.ui.purchaseList,
    streamPriorities: state.appConfiguration.playerStreamPriorities,
    usePackPurchaseApi: state.appConfiguration.usePackPurchaseApi,
    userRights: state.appConfiguration.rightsUser,
    viewingHistory: state.ui.viewingHistory,
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
    wishlist: WishlistCache.checkGet(state.ui.wishlist, state.ui.wishlistStatus).ids,
    wishlistStatus: state.ui.wishlistStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxCardModalDispatchToPropsType => {
  return {
    localAddToFavoriteList: (titId: string, signal?: AbortSignal): Promise<any> => dispatch(addToFavoriteList(titId, signal)),

    localAddToWishlist: (assetId: string, channelId: string, signal?: AbortSignal): Promise<any> => dispatch(addToWishlist(assetId, channelId, signal)),

    localGetImageSourceUrl: (assetId: string, width: number, height: number): Promise<?string> => dispatch(getImageSourceUrl(assetId, width, height)),

    localGetImageUrl: (assetId: string, width: number, height: number, theme?: ThemeType, signal?: AbortSignal): Promise<any> =>
      dispatch(
        getImageUrl(
          {
            assetId,
            height,
            theme,
            width,
          },
          signal,
        ),
      ),

    localGetPurchaseInfoPerAsset: (id: string, channelId: string, signal?: AbortSignal): Promise<any> => dispatch(getPurchaseInfoPerAsset(id, channelId, signal)),

    localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal): Promise<any> => dispatch(getVodLocations(locations, signal)),

    localHideModal: () => dispatch(hideModal()),

    localRemoveFromFavoriteList: (titId: string, signal?: AbortSignal): Promise<any> => dispatch(removeFromFavoriteList(titId, signal)),

    localRemoveFromWishlist: (assetId: string, channelId: string, signal?: AbortSignal): Promise<any> => dispatch(removeFromWishlist(assetId, channelId, signal)),

    localResetSectionPageIndices: (prefix?: string): void => dispatch(resetSectionPageIndices(prefix)),

    localSendV8LocationCatchupForAssetRequest: (assetId: string, startDate: number, range: number, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationCatchupForAssetRequest(assetId, startDate, range, undefined, signal)),

    localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataLocationRequest(assetId, signal)),

    localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataRequest(assetId, type, signal)),

    localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8RecordingsMetadataRequest(recordId, signal)),

    showVodPurchaseDialog: (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => dispatch(showVodPurchaseModal(vodPurchaseData)),
  };
};

const CardModal: React.ComponentType<ModalState> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CardModalView);

export default CardModal;
