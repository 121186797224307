/* @flow */

import './ItemOverlay.scss';
import * as React from 'react';
import {
  type ActionsType,
  type CompleteItemOverlayPropType,
  type ItemOverlayPropType,
  type ItemOverlayStateType,
  type ReduxItemOverlayDispatchToPropsType,
  type ReduxItemOverlayReducerStateType,
  TruncateState,
} from './ItemOverlayConstsAndTypes';
import { type BASE_VOD_PURCHASE_DATA_TYPE, PendingOperationKind, PendingOperationReason, type VOD_PURCHASE_DATA_TYPE, storePendingOperation } from '../../../helpers/rights/pendingOperations';
import { BOVodAssetStatus, getTitIdFromProviderInfo, getVodLocationFromVtiId, hasProgramTrailer, hasSeriesTrailer } from '../../../helpers/videofutur/metadata';
import { HeightKind, WidthKind } from '../../buttons/types';
import {
  ItemContent,
  ItemType,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { PictoHeartEmpty, PictoHeartFull, PictoReplay, PictoTrailer } from '@ntg/components/dist/pictos/Element';
import PricingVod, { ContextKind as PricingContext } from '../../pricingVod/PricingVod';
import RecordButton, { DisplayMode, ContextKind as RecordContext } from '../../recordButton/RecordButton';
import ResizeDetectorWrapper, { type ResizeType } from '../../resizeDetectorWrapper/ResizeDetectorWrapper';
import { TILE_SYNOPSIS_LINE_HEIGHT, TILE_SYNOPSIS_TAGLINE_LINE_HEIGHT } from '../../../helpers/ui/constants';
import { TileConfig, TileConfigTileType, TileOnFocus } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { addToWishlist, removeFromWishlist } from '../../../redux/netgemApi/actions/personalData/wishlist';
import { getSynopsis, getTagline, renderProgramDetails } from '../../../helpers/ui/metadata/Format';
import { getTimeText, getWatchingStatus, renderProgramTitle, renderSeriesTitle } from './helper';
import { hideModal, showVodPurchaseModal } from '../../../redux/modal/actions';
import { isFavoriteGranted, isWishlistGranted } from '../../../helpers/rights/wishlist';
import { BroadcastStatus } from '../../../helpers/ui/location/Format';
import ButtonFX from '../../buttons/ButtonFX';
import type { CARD_DATA_MODAL_TYPE } from '../../modal/cardModal/CardModalConstsAndTypes';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import { ExtendedItemType } from '../../../helpers/ui/item/types';
import { FEATURE_SUBSCRIPTION } from '../../../redux/appConf/constants';
import InfiniteCircleLoader from '../../loader/infiniteCircleLoader';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import { NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR } from '../../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY } from '../../../libs/netgemLibrary/v8/types/Npvr';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { PROGRAM_INFO } from '../../../helpers/ui/metadata/Types';
import { RegistrationType } from '../../../redux/appRegistration/types/types';
import TextScroller from '../../textScroller/TextScroller';
import { type Undefined } from '@ntg/utils/dist/types';
import WishlistCache from '../../../helpers/wishlist/WishlistCache';
import addToFavoriteList from '../../../redux/netgemApi/actions/videofutur/addFavorite';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getDistributorId } from '../../../helpers/ui/item/distributor';
import { getSeriesCardUrlDefinition } from '../../../helpers/ui/section/tile';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isPlaybackGranted } from '../../../helpers/rights/playback';
import removeFromFavoriteList from '../../../redux/netgemApi/actions/videofutur/deleteFavorite';
import { showDebug } from '../../../helpers/debug/debug';

const InitialState = Object.freeze({
  isLoading: true,
  synopsis: null,
  textHeight: 0,
  truncateState: TruncateState.Initializing,
});

const textHeightCache: {| [string]: number |} = {};

class ItemOverlayView extends React.PureComponent<CompleteItemOverlayPropType, ItemOverlayStateType> {
  abortController: AbortController;

  wrapperHeight: number;

  constructor(props: CompleteItemOverlayPropType) {
    super(props);

    this.abortController = new AbortController();
    this.wrapperHeight = 0;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.updateSynopsis();
  }

  componentDidUpdate(prevProps: CompleteItemOverlayPropType) {
    const {
      item: { type },
      programMetadata,
      programTitle,
      seriesMetadata,
      tvLocationMetadata,
      vodLocationsMetadata,
    } = this.props;
    const {
      programMetadata: prevProgramMetadata,
      programTitle: prevProgramTitle,
      seriesMetadata: prevSeriesMetadata,
      tvLocationMetadata: prevTVLocationMetadata,
      vodLocationsMetadata: prevVodLocationsMetadata,
    } = prevProps;

    if (programMetadata !== prevProgramMetadata || seriesMetadata !== prevSeriesMetadata) {
      this.updateSynopsis();
    }

    if (
      (prevVodLocationsMetadata === null && vodLocationsMetadata !== prevVodLocationsMetadata) ||
      (prevTVLocationMetadata === null && tvLocationMetadata !== prevTVLocationMetadata) ||
      (programTitle !== prevProgramTitle && type === ItemType.Universe)
    ) {
      this.setIsReady();
    }
  }

  setIsReady = () => {
    this.setState({ isLoading: false });
  };

  measureTextHeight = (text: string | null, width: number, isTagLine: boolean): number => {
    if (typeof text !== 'string' || text === '') {
      return 0;
    }

    const key = `${text}:${width}`;
    const { key: cachedHeight } = textHeightCache;

    if (typeof cachedHeight === 'number') {
      return cachedHeight;
    }

    const div = document.createElement('DIV');
    div.innerHTML = text;
    div.classList.add('synopsisMeasure');
    if (isTagLine) {
      div.classList.add('tagLine');
    }
    div.style.width = `${width}px`;
    document.body?.appendChild(div);
    const height = div.offsetHeight;
    document.body?.removeChild(div);

    textHeightCache[key] = height;

    return height;
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  showDebugInfo = () => {
    const { state } = this;

    showDebug('Section Item Overlay', {
      instance: this,
      instanceFields: ['wrapperHeight'],
      misc: { watchingStatus: this.localGetWatchingStatus() },
      state,
      stateFields: ['isLoading', 'synopsis', 'textHeight', 'truncateState'],
    });
  };

  updateSynopsis = () => {
    const {
      contentType,
      hoverContent,
      item: { locType, type },
      programMetadata,
      seriesMetadata,
      tvLocationMetadata,
      vodLocationsMetadata,
    } = this.props;

    let assetMetadata: ?NETGEM_API_V8_METADATA = null;
    let synopsis: string | null = '';

    if (hoverContent.has(TileConfig.SeriesSynopsis)) {
      assetMetadata = seriesMetadata;
    } else if (hoverContent.has(TileConfig.ProgramSynopsis)) {
      assetMetadata = programMetadata;
    }

    if (assetMetadata) {
      if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_EST) {
        // Display tag line on VOD tiles
        synopsis = getTagline(seriesMetadata ?? programMetadata, Localizer.language) ?? '';
      } else {
        // Display synopsis on all other tiles
        synopsis = assetMetadata ? getSynopsis(assetMetadata, Localizer.language) : '';
      }
    }

    this.setState({ synopsis, textHeight: 0, truncateState: TruncateState.Initializing });

    if (contentType === ItemContent.NetgemSVOD || (contentType === ItemContent.VODOrDeeplink && type === ItemType.Series) || vodLocationsMetadata !== null || tvLocationMetadata !== null) {
      // Do not wait for location metadata to load for these contents or if metadata is already loaded
      this.setIsReady();
    }
  };

  localGetWatchingStatus = (): number | null => {
    const { contentType, item, programMetadata, seriesMetadata, viewingHistory, viewingHistoryStatus, vodStatus } = this.props;

    return getWatchingStatus(item, viewingHistory, viewingHistoryStatus, contentType, programMetadata, seriesMetadata, vodStatus);
  };

  isFavorite = (): boolean => {
    const {
      authority,
      favoriteList,
      item: { selectedProgramId: programId, seriesId },
      programMetadata,
      seriesMetadata,
      wishlist,
      wishlistStatus,
    } = this.props;

    if (authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
      if (!programMetadata) {
        return false;
      }

      const { providerInfo } = programMetadata;

      if (!providerInfo) {
        return false;
      }

      const titId = getTitIdFromProviderInfo(providerInfo, seriesMetadata);

      return favoriteList.includes(titId) ?? false;
    }

    if (wishlistStatus !== LoadableStatus.Loaded) {
      return false;
    }

    return wishlist.has(seriesId || programId);
  };

  getTimeElement = (description: Set<TileConfig>, className?: string): React.Node => {
    const { item, now } = this.props;

    const time = getTimeText(item, now, description);

    if (!time) {
      return null;
    }

    return <div className={clsx('text', className)}>{time}</div>;
  };

  getDetailsElement = (description: Set<TileConfig>): React.Node => {
    const { item, programMetadata, vodLocationsMetadata } = this.props;

    let info = 0;

    if (description.has(TileConfig.Year)) {
      info |= PROGRAM_INFO.ReleaseDate; // eslint-disable-line no-bitwise
    }

    if (description.has(TileConfig.Genre)) {
      info |= PROGRAM_INFO.Genre; // eslint-disable-line no-bitwise
    }

    if (description.has(TileConfig.Language)) {
      info |= PROGRAM_INFO.Language; // eslint-disable-line no-bitwise
    }

    if (description.has(TileConfig.ParentalGuidance)) {
      info |= PROGRAM_INFO.ParentalGuidance; // eslint-disable-line no-bitwise
    }

    if (info === 0) {
      return null;
    }

    return <div className='details other'>{renderProgramDetails(item, programMetadata, vodLocationsMetadata, info)}</div>;
  };

  toggleWishlist = (toggler: (string, string, AbortSignal) => Promise<any>) => {
    const {
      channels,
      commercialOffers,
      defaultRights,
      isRegisteredAsGuest,
      isSubscriptionFeatureEnabled,
      item,
      item: {
        selectedLocation: { channelId },
        selectedProgramId,
      },
      localHideModal,
      programMetadata,
      seriesMetadata,
      tileConfig: { type: tileType },
      tvLocationMetadata,
      userRights,
      vodLocationsMetadata,
      vtiId,
    } = this.props;
    const {
      abortController: { signal },
    } = this;
    const assetId = seriesMetadata?.id ?? selectedProgramId;

    if (!channelId) {
      // Channel Id is null
      return;
    }

    const locationMetadata = tvLocationMetadata ?? getVodLocationFromVtiId(vodLocationsMetadata, vtiId);

    const cardData: CARD_DATA_MODAL_TYPE = {
      item,
      programMetadata,
      seriesMetadata,
      tileType,
    };

    if (!isWishlistGranted(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, locationMetadata?.location, channels, commercialOffers, defaultRights, userRights)) {
      // Add to wishlist denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    toggler(assetId, channelId, signal).catch((error) => ignoreIfAborted(signal, error));
  };

  toggleFavorite = (toggler: (string, AbortSignal) => Promise<any>) => {
    const {
      channels,
      commercialOffers,
      defaultRights,
      isRegisteredAsGuest,
      isSubscriptionFeatureEnabled,
      item,
      localHideModal,
      tileConfig: { type: tileType },
      programMetadata,
      seriesMetadata,
      userRights,
      vodLocationsMetadata,
      vodStatus,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (!programMetadata) {
      return;
    }

    const { providerInfo } = programMetadata;

    if (!providerInfo) {
      return;
    }

    const locationMetadata = getVodLocationFromVtiId(vodLocationsMetadata, vodStatus?.vtiId);
    const cardData: CARD_DATA_MODAL_TYPE = {
      item,
      programMetadata,
      seriesMetadata,
      tileType,
    };

    const titId = getTitIdFromProviderInfo(providerInfo, seriesMetadata);

    if (!isFavoriteGranted(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, titId, locationMetadata?.location, channels, commercialOffers, defaultRights, userRights)) {
      // Add to favorite list denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    toggler(titId, signal).catch((error) => ignoreIfAborted(signal, error));
  };

  handleTrailerButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { programMetadata, seriesMetadata } = this.props;

    if (programMetadata) {
      Messenger.emit(MessengerEvents.OPEN_PLAYER, {
        programMetadata,
        seriesMetadata,
        type: ExtendedItemType.Trailer,
      });
    }
  };

  handleAddToWishlistButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { authority, localAddToWishlist, localAddToFavoriteList } = this.props;

    if (authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
      this.toggleFavorite(localAddToFavoriteList);
    } else {
      this.toggleWishlist(localAddToWishlist);
    }
  };

  handleRemoveFromWishlistButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { authority, localRemoveFromWishlist, localRemoveFromFavoriteList } = this.props;

    if (authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
      this.toggleFavorite(localRemoveFromFavoriteList);
    } else {
      this.toggleWishlist(localRemoveFromWishlist);
    }
  };

  handlePlayButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const {
      authority,
      channels,
      contentType,
      commercialOffers,
      defaultOnItemClick,
      defaultRights,
      isRegisteredAsGuest,
      isSubscriptionFeatureEnabled,
      item,
      localHideModal,
      onItemClick,
      previewCatchupScheduledEventId,
      programMetadata,
      seriesMetadata,
      tileConfig: { type: tileType },
      tvLocationMetadata,
      userRights,
      viewingHistoryId,
      vodLocationsMetadata,
      vtiId,
    } = this.props;

    const locationMetadata = tvLocationMetadata ?? getVodLocationFromVtiId(vodLocationsMetadata, vtiId);
    const distributorId = locationMetadata?.location?.providerInfo?.distributorId ?? null;

    const cardData: CARD_DATA_MODAL_TYPE = {
      distributorId,
      item,
      programMetadata,
      seriesMetadata,
      tileType,
      urlDefinition: undefined,
      viewingHistoryId,
      vtiId,
    };

    if (seriesMetadata && tvLocationMetadata) {
      // In case of TV series item, add the series card URL definition (if found) so that the episodes will be displayed on the card
      const urlDefinition = getSeriesCardUrlDefinition(onItemClick, defaultOnItemClick);
      if (urlDefinition !== null) {
        cardData.urlDefinition = urlDefinition;
      }
    }

    if (!isPlaybackGranted(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, authority, contentType, locationMetadata?.location, channels, commercialOffers, defaultRights, userRights)) {
      // Playback denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    if (contentType === ItemContent.VODOrDeeplink) {
      if (programMetadata && vtiId) {
        Messenger.emit(MessengerEvents.OPEN_PLAYER, {
          distributorId,
          item,
          locationId: locationMetadata?.location.id,
          programMetadata,
          seriesMetadata,
          tileType,
          type: ExtendedItemType.VOD,
          viewingHistoryId,
          vtiId,
        });
      }
    } else {
      Messenger.emit(MessengerEvents.OPEN_PLAYER, {
        authority,
        item,
        previewCatchupScheduledEventId,
        programMetadata,
        seriesMetadata,
        type: ExtendedItemType.TV,
      });
    }
  };

  handleReplayButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { programMetadata, seriesMetadata, startoverItem } = this.props;

    if (programMetadata && startoverItem) {
      Messenger.emit(MessengerEvents.OPEN_PLAYER, {
        item: startoverItem,
        programMetadata,
        seriesMetadata,
        type: ExtendedItemType.TV,
      });
    }
  };

  vodPurchaseModalClosedCallback = (data: any): void => {
    const { item, programMetadata, purchaseInfo, seriesMetadata, vodLocationsMetadata } = this.props;

    if (!data) {
      // Purchase cancelled
      return;
    }

    const { locationId, playNow, viewingHistoryId, vtiId } = data;

    if (playNow && vtiId) {
      Messenger.emit(MessengerEvents.OPEN_PLAYER, {
        distributorId: getDistributorId(vodLocationsMetadata, purchaseInfo),
        item,
        locationId,
        programMetadata,
        seriesMetadata,
        type: ExtendedItemType.VOD,
        viewingHistoryId,
        vtiId,
      });
    }
  };

  handlePurchaseButtonOnClick = (data: BASE_VOD_PURCHASE_DATA_TYPE): void => {
    const {
      isRegisteredAsGuest,
      item,
      localHideModal,
      programMetadata,
      seriesMetadata,
      showVodPurchaseDialog,
      tileConfig: { type: tileType },
    } = this.props;

    const { definition, displayPrice, distributorId, itemCount, licenseDuration, locationId, otherVtiId, purchaseType, vodLocationMetadata, vtiId } = data;

    if (!programMetadata) {
      return;
    }

    const providerInfo = vodLocationMetadata?.providerInfo ?? programMetadata.providerInfo;

    const purchaseData: VOD_PURCHASE_DATA_TYPE = {
      definition,
      displayPrice,
      distributorId,
      item,
      itemCount,
      licenseDuration,
      locationId,
      otherVtiId,
      programMetadata,
      purchaseType,
      seriesMetadata,
      viewingHistoryId: providerInfo?.viewingHistoryId,
      vodLocationMetadata,
      vtiId,
    };

    if (isRegisteredAsGuest) {
      // Guest mode: let's open the sign-in page
      storePendingOperation({
        item,
        pendingOperation: {
          operationType: PendingOperationKind.Purchase,
          purchaseData,
          reason: PendingOperationReason.RequireAccount,
        },
        programMetadata,
        seriesMetadata,
        tileType,
      });
      localHideModal();
      Messenger.emit(MessengerEvents.SHOW_SIGN_IN, PendingOperationKind.Purchase);
    } else {
      Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, this.vodPurchaseModalClosedCallback);
      showVodPurchaseDialog(purchaseData);
    }
  };

  renderVodActions = (pictoGem: React.Node): ActionsType => {
    const {
      tileConfig: { type: tileType },
      vodStatus,
    } = this.props;

    const status = vodStatus?.status ?? BOVodAssetStatus.Unknown;
    const actions = [pictoGem];

    const progress = this.localGetWatchingStatus();

    const widthKind = tileType === TileConfigTileType.LandscapeBig || tileType === TileConfigTileType.LandscapeVod ? WidthKind.Large : WidthKind.Stretched;

    const playBtn =
      status === BOVodAssetStatus.Free || status === BOVodAssetStatus.Rented || status === BOVodAssetStatus.Bought ? (
        <ButtonFX allowZeroProgress={false} heightKind={HeightKind.Small} key='play' onClick={this.handlePlayButtonOnClick} progress={progress} widthKind={widthKind}>
          {Localizer.localize('common.actions.watch')}
        </ButtonFX>
      ) : null;

    const mainButton = (
      <>
        {this.renderPricingVod()}
        {playBtn}
      </>
    );

    return {
      actions,
      mainButton,
    };
  };

  renderTVActions = (pictoGem: React.Node): ActionsType => {
    const {
      broadcastStatus,
      defaultOnItemClick,
      item,
      item: { locType },
      onItemClick,
      previewCatchupScheduledEventId,
      programMetadata,
      seriesMetadata,
      startoverItem,
      tileConfig: { type: tileType },
      tvLocationMetadata,
    } = this.props;

    const pictoReplay = startoverItem ? <PictoReplay key='replay' onClick={this.handleReplayButtonOnClick} /> : null;

    const actions = [pictoGem, pictoReplay];

    let mainButton = null;

    let cardDataUrlDefinition: Undefined<NETGEM_API_V8_URL_DEFINITION> = undefined;
    if (seriesMetadata && tvLocationMetadata) {
      // In case of TV series item, add the series card URL definition (if found) so that the episodes will be displayed on the card
      const urlDefinition = getSeriesCardUrlDefinition(onItemClick, defaultOnItemClick);
      if (urlDefinition) {
        cardDataUrlDefinition = urlDefinition;
      }
    }

    if (broadcastStatus === BroadcastStatus.Future) {
      // Future scheduled event
      mainButton = (
        <RecordButton
          broadcastStatus={broadcastStatus}
          cardDataUrlDefinition={cardDataUrlDefinition}
          context={RecordContext.Tile}
          displayMode={DisplayMode.Button}
          item={item}
          key='recordButton'
          location={tvLocationMetadata?.location ?? null}
          previewCatchupScheduledEventId={previewCatchupScheduledEventId}
          programMetadata={programMetadata}
          seriesMetadata={seriesMetadata}
          tileType={tileType}
        />
      );
    } else if (broadcastStatus !== BroadcastStatus.Unknown) {
      // Live scheduled event, catchup or recording
      const buttonTextKey = broadcastStatus === BroadcastStatus.Live && locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING ? 'common.actions.watch_live' : 'common.actions.watch';
      const widthKind = tileType === TileConfigTileType.LandscapeBig || tileType === TileConfigTileType.LandscapeVod ? WidthKind.Large : WidthKind.Stretched;
      actions.push(
        <RecordButton
          broadcastStatus={broadcastStatus}
          cardDataUrlDefinition={cardDataUrlDefinition}
          context={RecordContext.Tile}
          displayMode={DisplayMode.Picto}
          item={item}
          key='recordButton'
          kind={locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP ? NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY : undefined}
          location={tvLocationMetadata?.location ?? null}
          previewCatchupScheduledEventId={previewCatchupScheduledEventId}
          programMetadata={programMetadata}
          seriesMetadata={seriesMetadata}
          tileType={tileType}
        />,
      );
      mainButton = (
        <ButtonFX
          allowZeroProgress={broadcastStatus === BroadcastStatus.Live}
          heightKind={HeightKind.Small}
          key='play'
          onClick={this.handlePlayButtonOnClick}
          progress={broadcastStatus !== BroadcastStatus.Live ? this.localGetWatchingStatus() : undefined}
          widthKind={widthKind}
        >
          {Localizer.localize(buttonTextKey)}
        </ButtonFX>
      );
    }

    return {
      actions,
      mainButton,
    };
  };

  handleSynopsisOnResize = (data: ResizeType): void => {
    const {
      item: { locType },
    } = this.props;
    const { synopsis, truncateState } = this.state;
    const { height: availableHeight, width: availableWidth } = data;

    const isTagLine = locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_EST;

    if (truncateState !== TruncateState.Initializing || availableHeight === null || availableWidth === null) {
      // Component not mounted or truncate state already initialized
      return;
    }

    // Synopsis wrapper has been rendered

    // Fix wrapper height for future renderings
    this.wrapperHeight = availableHeight;

    // Height needed to fully display synopsis
    const requiredHeight = this.measureTextHeight(synopsis, availableWidth, isTagLine);

    let newTextHeight = 0;
    let newTruncateState = truncateState;

    if (requiredHeight > availableHeight) {
      // Synopsis has to be truncated to following height
      const lineHeight = isTagLine ? TILE_SYNOPSIS_TAGLINE_LINE_HEIGHT : TILE_SYNOPSIS_LINE_HEIGHT;
      newTextHeight = Math.floor(availableHeight / lineHeight - 1) * lineHeight;
      newTruncateState = TruncateState.Truncated;
    } else {
      newTextHeight = requiredHeight;
      newTruncateState = TruncateState.NotTruncated;
    }

    // Text height is increased by 1 pixel to accommodate for rounding error
    setTimeout(() => {
      this.setState({ textHeight: newTextHeight + 1, truncateState: newTruncateState });
    }, 0);
  };

  renderSynopsis = (): React.Node => {
    const {
      item: { locType },
    } = this.props;
    const { synopsis, textHeight, truncateState } = this.state;

    const isTagLine = locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_EST;

    // Wrapper height is fixed so that it doesn't grow when the synopsis text is rendered
    const wrapperStyle = truncateState === TruncateState.Truncated && this.wrapperHeight > 0 ? { height: this.wrapperHeight, maxHeight: this.wrapperHeight } : undefined;

    const synopsisElt =
      truncateState === TruncateState.Initializing ? null : (
        <div className='text' style={{ height: `${textHeight}px` }}>
          {synopsis}
        </div>
      );

    return (
      <div className={clsx('synopsisWrapper', (synopsis === null || synopsis === '') && 'empty', isTagLine && 'tagLine')} style={wrapperStyle}>
        <div className='synopsis'>{synopsisElt}</div>
        {truncateState === TruncateState.Truncated ? <div className='ellipsis'>[...]</div> : null}
      </div>
    );
  };

  renderPricingVod = (): React.Node => {
    const {
      item: { purchasable, type },
      tileConfig: { type: tileType },
      purchaseInfo,
      vodLocationsMetadata,
      vodStatus,
    } = this.props;

    if (!vodStatus && !purchaseInfo) {
      return null;
    }

    let status = BOVodAssetStatus.Unknown;
    const expirationTime = vodStatus?.expirationTime ?? 0;

    if (vodStatus) {
      ({ status } = vodStatus);
    } else if (purchaseInfo) {
      status = BOVodAssetStatus.Locked;
    }

    if (status === BOVodAssetStatus.Unknown) {
      // Purchase list not loaded yet
      return null;
    }

    if ((typeof purchasable !== 'undefined' && !purchasable) || status === BOVodAssetStatus.Free) {
      // No message on tiles for free VOD items
      return null;
    }

    // TODO: handle BOVodAssetStatus.FreeButAvailableInFuture here if some movies with future availability appear in the catalog

    const context = tileType === TileConfigTileType.LandscapeBig || tileType === TileConfigTileType.LandscapeVod ? PricingContext.LandscapeTile : PricingContext.RegularTile;

    return (
      <PricingVod
        context={context}
        expirationTime={expirationTime}
        isVodSeries={type === ItemType.Series}
        locationsMetadata={vodLocationsMetadata}
        onClick={this.handlePurchaseButtonOnClick}
        purchaseInfo={purchaseInfo}
        status={status}
      />
    );
  };

  renderHoverContentElements = (): React.Node => {
    const { hoverContent, programMetadataStatus, programTitle, seriesMetadataStatus, seriesTitle } = this.props;

    let titleElement = null;
    let subtitleElement = null;

    // Series title
    if (hoverContent.has(TileConfig.SeriesTitle)) {
      titleElement = renderSeriesTitle(programMetadataStatus, seriesMetadataStatus, seriesTitle);
    }

    // Program title
    if (hoverContent.has(TileConfig.ProgramTitle)) {
      const programTitleElement = renderProgramTitle(programMetadataStatus, seriesMetadataStatus, programTitle, null, titleElement ? 'secondLineInfo' : '', titleElement === null);
      if (titleElement) {
        subtitleElement = programTitleElement;
      } else {
        titleElement = programTitleElement;
      }
    }

    // Date & duration
    const timeElement = this.getTimeElement(hoverContent, 'thirdLineInfo');

    // Year, genre, language & parental guidance
    const detailsElement = this.getDetailsElement(hoverContent);

    return (
      <>
        <TextScroller scrollOnMount style={{ font: '18px var(--bold-font)', maxHeight: '24px', transform: 'scale3d(1.22, 1.22, 1)' }}>
          {titleElement}
        </TextScroller>
        {subtitleElement ? (
          <TextScroller scrollOnMount style={{ font: '11px var(--light-font)', maxHeight: '16px' }}>
            {subtitleElement}
          </TextScroller>
        ) : null}
        {timeElement}
        {detailsElement}
      </>
    );
  };

  renderUnknownProgram = (): React.Node => {
    const {
      isInLiveSection,
      onClick,
      onItemClick,
      tileConfig: { type: tileType },
    } = this.props;

    if (!isInLiveSection) {
      // Do not display overlay when nothing is known about program
      return null;
    }

    const widthKind = tileType === TileConfigTileType.LandscapeBig || tileType === TileConfigTileType.LandscapeVod ? WidthKind.Large : WidthKind.Stretched;

    // No info for current program but since it's a live tile, allow to open player with channel only
    return (
      <div className={clsx('overlay', 'bottom', !onItemClick && 'notClickable')} onClick={onClick}>
        <ButtonFX heightKind={HeightKind.Small} key='play' onClick={this.handlePlayButtonOnClick} widthKind={widthKind}>
          {Localizer.localize('common.actions.watch_live')}
        </ButtonFX>
      </div>
    );
  };

  renderIconBar = (actions: Array<React.Node>): React.Node => (
    <div className='iconBar'>
      {actions.map((picto, index) =>
        picto ? (
          // eslint-disable-next-line react/no-array-index-key
          <div className='iconContainer' key={index}>
            {picto}
          </div>
        ) : null,
      )}
    </div>
  );

  render(): React.Node {
    const {
      contentType,
      isFocused,
      item: { type: itemType },
      onClick,
      onItemClick,
      programMetadata,
      seriesMetadata,
      streamPriorities,
      tileConfig: { onFocus },
    } = this.props;
    const { isLoading } = this.state;

    if (!isFocused || onFocus === TileOnFocus.Selection) {
      // Tile is not hovered: overlay is not displayed
      return null;
    }

    if (!programMetadata) {
      return this.renderUnknownProgram();
    }

    const fullHeartPicto = <PictoHeartFull className='fullHeart' key='heart' onClick={this.handleRemoveFromWishlistButtonOnClick} />;

    const emptyHeartPicto = <PictoHeartEmpty className='emptyHeart' key='heart' onClick={this.handleAddToWishlistButtonOnClick} />;

    const pictoWishlist = this.isFavorite() ? fullHeartPicto : emptyHeartPicto;

    const { actions, mainButton } = contentType === ItemContent.VODOrDeeplink ? this.renderVodActions(pictoWishlist) : this.renderTVActions(pictoWishlist);

    if ((hasProgramTrailer(streamPriorities, programMetadata) && itemType === ItemType.Program) || (hasSeriesTrailer(streamPriorities, seriesMetadata) && itemType === ItemType.Series)) {
      actions.push(<PictoTrailer key='trailer' onClick={this.handleTrailerButtonOnClick} />);
    }

    // Information displayed over tile, described by hoverContent in tileConfig (series tiles, program title, etc.)
    const hoverContentElements = this.renderHoverContentElements();

    const iconBar = this.renderIconBar(actions);

    return (
      <div className={clsx('overlay', !onItemClick && 'notClickable')} onClick={onClick}>
        {iconBar}
        {hoverContentElements}
        {isLoading ? (
          <InfiniteCircleLoader className='loader' />
        ) : (
          <ResizeDetectorWrapper className='resizeDetectorSynopsis' handleHeight handleWidth onResize={this.handleSynopsisOnResize} refreshMode='debounce' refreshRate={50}>
            {this.renderSynopsis()}
          </ResizeDetectorWrapper>
        )}
        {mainButton}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxItemOverlayReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
    commercialOffers: state.appConfiguration.rightsCommercialOffers,
    defaultOnItemClick: state.ui.defaultActions ? state.ui.defaultActions.onItemClick : null,
    defaultRights: state.appConfiguration.rightsDefault,
    favoriteList: state.ui.favoriteList || [],
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    npvrRecordingsFuture: state.npvr.npvrRecordingsFuture,
    npvrRecordingsList: state.npvr.npvrRecordingsList,
    npvrScheduledRecordingsList: state.npvr.npvrScheduledRecordingsList,
    purchaseList: state.appRegistration.registration === RegistrationType.RegisteredAsGuest ? {} : state.ui.purchaseList,
    streamPriorities: state.appConfiguration.playerStreamPriorities,
    userRights: state.appConfiguration.rightsUser,
    viewingHistory: state.ui.viewingHistory,
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
    wishlist: WishlistCache.checkGet(state.ui.wishlist, state.ui.wishlistStatus).ids,
    wishlistStatus: state.ui.wishlistStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxItemOverlayDispatchToPropsType => {
  return {
    localAddToFavoriteList: (titId: string, signal?: AbortSignal): Promise<any> => dispatch(addToFavoriteList(titId, signal)),

    localAddToWishlist: (assetId: string, channelId: string, signal?: AbortSignal): Promise<any> => dispatch(addToWishlist(assetId, channelId, signal)),

    localHideModal: () => dispatch(hideModal()),

    localRemoveFromFavoriteList: (titId: string, signal?: AbortSignal): Promise<any> => dispatch(removeFromFavoriteList(titId, signal)),

    localRemoveFromWishlist: (assetId: string, channelId: string, signal?: AbortSignal): Promise<any> => dispatch(removeFromWishlist(assetId, channelId, signal)),

    showVodPurchaseDialog: (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => dispatch(showVodPurchaseModal(vodPurchaseData)),
  };
};

const ItemOverlay: React.ComponentType<ItemOverlayPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ItemOverlayView);

export default ItemOverlay;
