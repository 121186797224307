/* @flow */

import './Slide.css';
import * as React from 'react';
import { BOVodAssetStatus, getVodStatusFromLocations } from '../../../helpers/videofutur/metadata';
import type { BasicFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { BroadcastStatus, getBroadcastStatus } from '../../../helpers/ui/location/Format';
import { ExtendedItemType, FAKE_EPG_LIVE_ITEM_TYPE } from '../../../helpers/ui/item/types';
import { ItemContent, type NETGEM_API_V8_FEED_ITEM, type NETGEM_API_V8_ITEM_LOCATION, NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { NETGEM_RECORDINGS_MAP, NETGEM_SCHEDULED_RECORDINGS_MAP } from '../../../libs/netgemLibrary/v8/types/Npvr';
import { formatSeasonEpisodeNbr, getTitle } from '../../../helpers/ui/metadata/Format';
import sendV8MetadataLocationRequest, { getVodLocations } from '../../../redux/netgemApi/actions/v8/metadataLocation';
import { APPLICATION_ID } from '../../../helpers/applicationCustomization/types';
import AccurateTimestamp from '../../../helpers/dateTime/AccurateTimestamp';
import type { BO_PURCHASE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/purchase';
import ButtonFX from '../../buttons/ButtonFX';
import type { CARD_DATA_MODAL_TYPE } from '../../modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import { FEATURE_SUBSCRIPTION } from '../../../redux/appConf/constants';
import type { ItemData } from './Types';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../libs/netgemLibrary/v8/types/RequestResponse';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_VIEWINGHISTORY } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import { PictoRecord } from '@ntg/components/dist/pictos/Element';
import { RegistrationType } from '../../../redux/appRegistration/types/types';
import StatusPicto from '../../statusPicto/StatusPicto';
import type { VOD_STATUS_TYPE } from '../../../helpers/ui/metadata/Types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createItemFromChannel } from '../../../helpers/ui/item/channel';
import { getDistributorId } from '../../../helpers/ui/item/distributor';
import { getEpisodeAndSeriesRecordStatus } from '../../../helpers/npvr/recording';
import { getIntegerPercentage } from '../../../helpers/maths/maths';
import { getProgress } from '../../../helpers/viewingHistory/ViewingHistory';
import { getSeriesCardUrlDefinition } from '../../../helpers/ui/section/tile';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { hideModal } from '../../../redux/modal/actions';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isPlaybackGranted } from '../../../helpers/rights/playback';
import { isVodItem } from '../../../helpers/ui/item/metadata';
import { showDebug } from '../../../helpers/debug/debug';

type ReduxItemSlideDispatchToPropsType = {|
  +localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal) => Promise<any>,
  +localHideModal: BasicFunction,
  +localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal) => Promise<any>,
|};

type ReduxItemSlideReducerStateType = {|
  +channels: ChannelMap,
  +commercialOffers: KeyValuePair<Array<string>> | null,
  +defaultOnItemClick: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE | null,
  +defaultRights: NETGEM_API_V8_RIGHTS | null,
  +isDebugModeEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +isSubscriptionFeatureEnabled: boolean,
  +npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  +npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  +npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP,
  +purchaseList: BO_PURCHASE_LIST_TYPE,
  +userRights: Array<string> | null,
  +viewingHistory: NETGEM_API_VIEWINGHISTORY,
  +viewingHistoryStatus: LoadableStatus,
|};

type DefaultProps = {|
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

type ItemSlidePropType = {|
  ...DefaultProps,
  +currentIndex: number,
  +hubItem: ?NETGEM_API_V8_FEED_ITEM,
  +item: NETGEM_API_V8_FEED_ITEM,
  +itemData: ItemData | null,
  +itemIndex: number,
  +sectionTitle: ?string,
|};

type CompleteItemSlidePropType = {|
  ...ItemSlidePropType,
  ...ReduxItemSlideReducerStateType,
  ...ReduxItemSlideDispatchToPropsType,
|};

type ItemSlideStateType = {|
  broadcastStatus: BroadcastStatus,
  isLiveRecording: boolean,
  now: number,
  previewCatchupScheduledEventId: ?string,
  tvLocationMetadata: NETGEM_API_V8_METADATA_SCHEDULE | null,
  vodLocations: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
|};

const InitialState = Object.freeze({
  broadcastStatus: BroadcastStatus.Unknown,
  isLiveRecording: false,
  now: 0,
  previewCatchupScheduledEventId: null,
  tvLocationMetadata: null,
  vodLocations: null,
});

// Time display and broadcast status are refreshed every second
const REFRESH_TIMEOUT = 1_000;

class ItemSlideView extends React.PureComponent<CompleteItemSlidePropType, ItemSlideStateType> {
  abortController: AbortController;

  broadcastStatusRefreshTimer: TimeoutID | null;

  isVod: boolean;

  timeRefreshTimer: TimeoutID | null;

  // Location Id of purchased location
  viewingHistoryId: ?string;

  // VtiId of purchased location
  vtiId: ?number;

  static defaultProps: DefaultProps = {
    onItemClick: undefined,
  };

  constructor(props: CompleteItemSlidePropType) {
    super(props);

    const { item } = props;

    this.abortController = new AbortController();
    this.broadcastStatusRefreshTimer = null;
    this.isVod = isVodItem(item);
    this.timeRefreshTimer = null;
    this.viewingHistoryId = null;
    this.vtiId = null;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: CompleteItemSlidePropType, prevState: ItemSlideStateType) {
    const {
      item: { selectedProgramId },
      npvrRecordingsList,
      npvrScheduledRecordingsList,
    } = this.props;
    const { broadcastStatus } = this.state;

    const {
      item: { selectedProgramId: prevSelectedProgramId },
      npvrRecordingsList: prevNpvrRecordingsList,
      npvrScheduledRecordingsList: prevNpvrScheduledRecordingsList,
    } = prevProps;
    const { broadcastStatus: prevBroadcastStatus } = prevState;

    if (selectedProgramId !== prevSelectedProgramId) {
      this.loadData();
    }

    if (npvrRecordingsList !== prevNpvrRecordingsList || npvrScheduledRecordingsList !== prevNpvrScheduledRecordingsList) {
      this.checkRecordingState();
    }

    if (broadcastStatus !== prevBroadcastStatus) {
      if (broadcastStatus === BroadcastStatus.Live) {
        // Item became live
        this.startTimeRefreshTimer();
      } else {
        // Item is not live anymore
        this.resetTimeRefreshTimer();
      }
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component Slide will unmount');

    this.resetTimeRefreshTimer();
    this.resetBroadcastStatusRefreshTimer();
  }

  handleDebugOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    if (event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();

      const { props, state } = this;

      showDebug('Carousel Item', {
        instance: this,
        instanceFields: ['broadcastStatusRefreshTimer', 'isVod', 'timeRefreshTimer', 'viewingHistoryId', 'vtiId'],
        props,
        propsFields: ['currentIndex', 'hubItem', 'item', 'itemData', 'itemIndex', 'sectionTitle'],
        state,
        stateFields: ['isLiveRecording', 'now', 'tvLocationMetadata', 'vodLocations'],
      });
    }
  };

  startTimeRefreshTimer: () => void = () => {
    this.resetTimeRefreshTimer();
    this.timeRefreshTimer = setTimeout(this.updateTime, REFRESH_TIMEOUT);
  };

  resetTimeRefreshTimer: () => void = () => {
    if (this.timeRefreshTimer) {
      clearTimeout(this.timeRefreshTimer);
      this.timeRefreshTimer = null;
    }
  };

  startBroadcastStatusRefreshTimer: () => void = () => {
    const { broadcastStatus } = this.state;

    this.resetBroadcastStatusRefreshTimer();

    if (broadcastStatus === BroadcastStatus.Past) {
      // A past item will never change, no need to check again
      return;
    }

    this.broadcastStatusRefreshTimer = setTimeout(this.updateBroadcastStatus, REFRESH_TIMEOUT);
  };

  resetBroadcastStatusRefreshTimer: () => void = () => {
    if (this.broadcastStatusRefreshTimer) {
      clearTimeout(this.broadcastStatusRefreshTimer);
      this.broadcastStatusRefreshTimer = null;
    }
  };

  updateTime: () => void = () => {
    this.setState({ now: AccurateTimestamp.nowInSeconds() }, this.startTimeRefreshTimer);
  };

  updateBroadcastStatus: () => void = () => {
    const { item } = this.props;
    this.setState({ broadcastStatus: getBroadcastStatus(item) }, this.startBroadcastStatusRefreshTimer);
  };

  checkRecordingState: () => void = () => {
    const {
      item,
      item: { selectedProgramId },
      itemData,
      npvrRecordingsFuture,
      npvrRecordingsList,
      npvrScheduledRecordingsList,
    } = this.props;
    const { broadcastStatus } = this.state;

    const { hasRecording } = getEpisodeAndSeriesRecordStatus(selectedProgramId, itemData?.seriesMetadata?.id, item, npvrRecordingsList, npvrRecordingsFuture, npvrScheduledRecordingsList);

    this.setState({ isLiveRecording: hasRecording && broadcastStatus === BroadcastStatus.Live });
  };

  loadData: () => void = () => {
    const { item } = this.props;

    if (!item) {
      return;
    }

    this.setState({ ...InitialState });

    this.resetTimeRefreshTimer();
    this.updateBroadcastStatus();
    this.checkRecordingState();
    this.loadVodLocations();
    this.loadTVLocationMetadata();
  };

  loadTVLocationMetadata: () => void = () => {
    const {
      item: {
        selectedLocation: { id },
        type,
      },
      localSendV8MetadataLocationRequest,
    } = this.props;
    const { broadcastStatus } = this.state;
    const {
      abortController: { signal },
      isVod,
    } = this;

    if (isVod || !id || type === FAKE_EPG_LIVE_ITEM_TYPE) {
      return;
    }

    localSendV8MetadataLocationRequest(id, signal)
      .then((requestResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
        signal.throwIfAborted();

        const {
          result,
          result: {
            location: { target },
          },
        } = requestResponse;
        this.setState({
          previewCatchupScheduledEventId: broadcastStatus === BroadcastStatus.Preview ? target : null,
          tvLocationMetadata: result,
        });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadVodLocations: () => void = () => {
    const {
      item: { locations },
      localGetVodLocations,
      purchaseList,
    } = this.props;
    const {
      abortController: { signal },
      isVod,
    } = this;

    if (!isVod || !locations) {
      return;
    }

    localGetVodLocations(locations, signal)
      .then((vodLocations) => {
        signal.throwIfAborted();

        const { viewingHistoryId, vtiId } = getVodStatusFromLocations(vodLocations, purchaseList);
        this.viewingHistoryId = viewingHistoryId;
        this.vtiId = vtiId;

        this.setState({ vodLocations });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  handlePlayButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const {
      channels,
      commercialOffers,
      defaultOnItemClick,
      defaultRights,
      isRegisteredAsGuest,
      isSubscriptionFeatureEnabled,
      item,
      item: { type: itemType },
      itemData,
      localHideModal,
      onItemClick,
      userRights,
    } = this.props;
    const { previewCatchupScheduledEventId, tvLocationMetadata, vodLocations } = this.state;
    const { isVod, viewingHistoryId, vtiId } = this;

    if (!itemData) {
      // Probably backend error
      return;
    }

    const { programMetadata, seriesMetadata, tileType } = itemData;
    const distributorId = getDistributorId(vodLocations);

    const cardData: CARD_DATA_MODAL_TYPE = {
      distributorId,
      item,
      programMetadata: programMetadata ?? null,
      seriesMetadata: seriesMetadata ?? null,
      tileType: tileType ?? TileConfigTileType.Gemtv,
      urlDefinition: undefined,
      viewingHistoryId,
      vtiId,
    };

    if (seriesMetadata && tvLocationMetadata) {
      // In case of TV series item, add the series card URL definition (if found) so that the episodes will be displayed on the card
      const urlDefinition = getSeriesCardUrlDefinition(onItemClick, defaultOnItemClick);
      if (urlDefinition !== null) {
        cardData.urlDefinition = urlDefinition;
      }
    }

    if (
      !isPlaybackGranted(
        isRegisteredAsGuest,
        isSubscriptionFeatureEnabled,
        cardData,
        programMetadata?.authority,
        ItemContent.TV,
        tvLocationMetadata?.location,
        channels,
        commercialOffers,
        defaultRights,
        userRights,
      )
    ) {
      // Playback denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    if (item && (programMetadata || itemType === FAKE_EPG_LIVE_ITEM_TYPE)) {
      if (isVod) {
        // Not sure this case is possible since there's no "Play" button for VOD slides, but if so, location Id (from VOD location metadata) should be passed
        Messenger.emit(MessengerEvents.OPEN_PLAYER, {
          distributorId,
          item,
          programMetadata,
          seriesMetadata,
          type: ExtendedItemType.VOD,
          viewingHistoryId,
          vtiId,
        });
      } else {
        Messenger.emit(MessengerEvents.OPEN_PLAYER, {
          item,
          previewCatchupScheduledEventId,
          programMetadata,
          seriesMetadata,
          type: ExtendedItemType.TV,
        });
      }
    }
  };

  handleItemClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const { item, itemData } = this.props;
    const { isVod } = this;

    if (!itemData) {
      // Probably backend error
      return;
    }

    const { programMetadata, seriesMetadata, tileType } = itemData;

    if (item && programMetadata) {
      Messenger.emit(MessengerEvents.ITEM_CLICKED, {
        item,
        programMetadata: programMetadata ?? null,
        seriesMetadata: seriesMetadata ?? null,
        tileType: tileType ?? TileConfigTileType.Gemtv,
        type: isVod ? ExtendedItemType.VOD : ExtendedItemType.TV,
      });
    }
  };

  renderOverTitle = (item: NETGEM_API_V8_FEED_ITEM): React.Node => {
    const { channels, hubItem, sectionTitle } = this.props;
    const { isVod } = this;

    if (isVod) {
      return <div className='text'>{sectionTitle}</div>;
    }

    const {
      selectedLocation: { channelId },
    } = item;
    if (!channelId) {
      return null;
    }

    const { [channelId]: channel } = channels;
    if (!channel) {
      return null;
    }

    const { epgid, name } = channel;
    const isClickable = process.env.REACT_APP_ID !== APPLICATION_ID.FranceChannel && (!hubItem || hubItem.id !== epgid);

    const handleChannelOnClick = () => {
      const channelItem = createItemFromChannel(channel);

      if (channelItem) {
        Messenger.emit(MessengerEvents.ITEM_CLICKED, { item: channelItem });
      }
    };

    return (
      <>
        <div className={clsx('text', isClickable && 'clickable')} onClick={isClickable ? handleChannelOnClick : undefined}>
          {name}
        </div>
        <div className='separator'>|</div>
      </>
    );
  };

  renderTitleInternal = (): string => {
    const { itemData } = this.props;

    if (itemData) {
      const { programMetadata, seriesMetadata } = itemData;

      if (seriesMetadata) {
        const translatedTitleText = getTitle(seriesMetadata, Localizer.language);
        if (translatedTitleText) {
          return translatedTitleText;
        }
      }

      if (programMetadata) {
        const { titles } = programMetadata;
        const seasonEpisodeText = formatSeasonEpisodeNbr(programMetadata);
        const translatedTitleText = getTranslatedText(titles, Localizer.language);

        if (seasonEpisodeText && translatedTitleText) {
          return `${seasonEpisodeText} - ${translatedTitleText}`;
        } else if (seasonEpisodeText) {
          return seasonEpisodeText;
        } else if (translatedTitleText) {
          return translatedTitleText;
        }
      }
    }

    return '\u00A0';
  };

  renderTitle = (): React.Node => <div className='title'>{this.renderTitleInternal()}</div>;

  getLiveProgress = (): number => {
    const {
      item: { endTime, startTime },
    } = this.props;
    const { now } = this.state;

    return getIntegerPercentage(now, startTime, endTime);
  };

  getWatchingStatus = (): number | null => {
    const {
      item,
      item: { locType },
      itemData,
      purchaseList,
      viewingHistory,
      viewingHistoryStatus,
    } = this.props;
    const { vodLocations } = this.state;
    const { isVod } = this;

    let vodStatus: VOD_STATUS_TYPE | null = null;

    if (isVod) {
      vodStatus = getVodStatusFromLocations(vodLocations, purchaseList);
      const { status } = vodStatus;
      if (status === BOVodAssetStatus.Locked) {
        // Item has been watched (at least partially) but its rent has expired
        return 0;
      }
    }

    if (!itemData) {
      // Probably backend error
      return 0;
    }

    const { programMetadata } = itemData;

    if (!programMetadata || viewingHistoryStatus !== LoadableStatus.Loaded || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT) {
      return 0;
    }

    return getProgress(viewingHistory, item, programMetadata, null, false, vodStatus);
  };

  renderActionPanel = (): React.Node => {
    const {
      item: { type },
    } = this.props;
    const { broadcastStatus } = this.state;
    const { isVod } = this;

    const progress = broadcastStatus === BroadcastStatus.Live ? this.getLiveProgress() : this.getWatchingStatus();

    const playButton =
      type === FAKE_EPG_LIVE_ITEM_TYPE || (!isVod && (broadcastStatus === BroadcastStatus.Past || broadcastStatus === BroadcastStatus.Preview || broadcastStatus === BroadcastStatus.Live)) ? (
        <ButtonFX allowZeroProgress={broadcastStatus === BroadcastStatus.Live} className='spacedButton' key='play' onClick={this.handlePlayButtonOnClick} progress={progress}>
          {Localizer.localize(broadcastStatus === BroadcastStatus.Live ? 'common.actions.watch_live' : 'common.actions.watch')}
        </ButtonFX>
      ) : null;
    const moreInfoButton =
      type !== FAKE_EPG_LIVE_ITEM_TYPE ? (
        <ButtonFX key='moreInfo' onClick={this.handleItemClick}>
          {Localizer.localize('common.actions.more_info')}
        </ButtonFX>
      ) : null;

    return (
      <>
        {playButton}
        {moreInfoButton}
      </>
    );
  };

  render(): React.Node {
    const { currentIndex, isDebugModeEnabled, item, itemIndex } = this.props;
    const { isLiveRecording } = this.state;
    const { isVod } = this;

    const liveRecordingIconElement = isLiveRecording ? (
      <div className='liveRecording'>
        <PictoRecord className='recording live' hasBackground />
        <div className='text'>{Localizer.localize('common.status.live_recording')}</div>
      </div>
    ) : null;

    return (
      <div className={clsx('itemSlide', isVod && 'vod', itemIndex === currentIndex && 'visible')} onClick={isDebugModeEnabled ? this.handleDebugOnClick : undefined}>
        <div className='infoContainer'>
          <div className='overTitle'>
            {this.renderOverTitle(item)}
            {isVod ? null : <StatusPicto item={item} />}
            {liveRecordingIconElement}
          </div>
          {this.renderTitle()}
          <div className='iconBar'>{this.renderActionPanel()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxItemSlideReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
    commercialOffers: state.appConfiguration.rightsCommercialOffers,
    defaultOnItemClick: state.ui.defaultActions ? state.ui.defaultActions.onItemClick : null,
    defaultRights: state.appConfiguration.rightsDefault,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    npvrRecordingsFuture: state.npvr.npvrRecordingsFuture,
    npvrRecordingsList: state.npvr.npvrRecordingsList,
    npvrScheduledRecordingsList: state.npvr.npvrScheduledRecordingsList,
    purchaseList: state.appRegistration.registration === RegistrationType.RegisteredAsGuest ? {} : (state.ui.purchaseList ?? {}),
    userRights: state.appConfiguration.rightsUser,
    viewingHistory: state.ui.viewingHistory,
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxItemSlideDispatchToPropsType => {
  return {
    localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal): Promise<any> => dispatch(getVodLocations(locations, signal)),
    localHideModal: () => dispatch(hideModal()),
    localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataLocationRequest(assetId, signal)),
  };
};

const ItemSlide: React.ComponentType<ItemSlidePropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ItemSlideView);

export default ItemSlide;
