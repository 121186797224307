/* @flow */

export const isUndefinedOrNull: (value: any) => boolean = (value) => typeof value === 'undefined' || value === null;

export type Undefined<T> = T | typeof undefined;

export type BasicFunction = () => void;

export type BasicPromiseFunction = () => Promise<void>;

export type KeyValuePair<T> = {| [string]: T |};
