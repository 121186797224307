/* @flow */

import type { BasicFunction, KeyValuePair } from '@ntg/utils/dist/types';
import type {
  NETGEM_API_V8_FEED,
  NETGEM_API_V8_FEED_ITEM,
  NETGEM_API_V8_FEED_RAW_ITEM,
  NETGEM_API_V8_ITEM_LOCATION,
  NETGEM_API_V8_ITEM_LOCATION_TYPE,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_VIEWINGHISTORY_LOCATION, VIEWING_HISTORY_TYPE } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import type { BO_FAVORITE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/favorite';
import type { CARD_DATA_MODAL_TYPE } from '../../modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../../redux/reducers';
import type { FeedRequestFunction } from '../../../libs/netgemLibrary/v8/types/Feed';
import { ISO8601_DURATION_ZERO } from '../../../helpers/dateTime/Format';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import type { NETGEM_API_V8_RAW_FEED } from '../../../libs/netgemLibrary/v8/types/FeedResult';
import type { NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import { type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { NETGEM_API_WISHLIST } from '../../../libs/netgemLibrary/v8/types/Wishlist';
import { SectionDisplayType } from '../../../helpers/ui/section/types';
import type { SortAndFilterType } from '../sortAndFilter/type';

export const FAKE_LOCATION: NETGEM_API_V8_ITEM_LOCATION = Object.freeze({
  channelId: '',
  duration: ISO8601_DURATION_ZERO,
  id: 'scheduledevent://',
  score: [0],
  startDate: '',
});

export const FAKE_SCHEDULED_EVENT_ITEM: NETGEM_API_V8_FEED_RAW_ITEM = Object.freeze({
  id: '',
  locations: undefined,
  parentalGuidance: {
    explanatoryText: [],
    minimumAge: 0,
  },
  score: [0],
});

export const FAKE_CHANNEL_ITEM: NETGEM_API_V8_FEED_RAW_ITEM = Object.freeze({
  id: '',
  locations: undefined,
  score: [0],
});

export type LocalPlayedItemType = {|
  ...NETGEM_API_VIEWINGHISTORY_LOCATION,
  id: string,
|};

export const PAGE_DISPLAY_COUNT = 2;

// Z-index will go from 200 (first section) to 200-n (last section) to ensure zoomed tiles go over next section's title
export const MAX_Z_INDEX = 200;

export const SWIPE = {
  // Time to wait before actually setting isSwiping to false after "swiped" event has been called (avoid card opening when swiping)
  CooldownTime: 10,
  // Used to transform velocity in a page step when swiping a section
  PageVelocityStep: 5,
};

export const LOCAL_PROVIDER_QUERY = {
  Catchup: 'catchup',
  Est: 'est',
  Recording: 'recording',
  Scheduledevent: 'scheduledevent',
  Svod: 'svod',
  Tvod: 'tvod',
};

export enum StandardSectionType {
  Regular,
  Live,
  ViewingHistory,
  Wishlist,
}

// Cannot be converted to enum because it's used as a key in an object
export type INTERVAL_TYPE = 'LIVE_STANDARD_SECTION' | 'LIVE_CACHED_STANDARD_SECTION';

export type ReduxSectionDispatchToPropsType = {|
  +localCleanUpViewingHistory: (viewingHistory: VIEWING_HISTORY_TYPE, feed: NETGEM_API_V8_FEED, locationTypes: Set<NETGEM_API_V8_ITEM_LOCATION_TYPE>) => Promise<any>,
  +localResetGridSectionId: BasicFunction,
  +localSavePageIndex: (sectionId: string, index: number) => void,
  +localSendV8LocationCatchupForAssetRequest: FeedRequestFunction,
  +localSendV8LocationEpgForAssetRequest: FeedRequestFunction,
  +localSendV8LocationVodForAssetRequest: FeedRequestFunction,
  +localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, searchString: ?string, signal?: AbortSignal) => Promise<any>,
  +localUpdateGridSectionId: (gridSectionId: string | null) => void,
|};

export type ReduxSectionReducerStateType = {|
  +channels: ChannelMap,
  +favoriteList: BO_FAVORITE_LIST_TYPE,
  +gridSectionId: ?string,
  +isDebugModeEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +recordingsListFeed: NETGEM_API_V8_RAW_FEED,
  +sectionPageIndices: KeyValuePair<number>,
  +sessionId: string,
  +state: CombinedReducers,
  +viewingHistory: VIEWING_HISTORY_TYPE,
  +viewingHistoryStatus: LoadableStatus,
  +wishlist: NETGEM_API_WISHLIST,
  +wishlistStatus: LoadableStatus,
|};

export type DefaultProps = {|
  +cardData?: CARD_DATA_MODAL_TYPE | null,
  +hubItem?: NETGEM_API_V8_FEED_ITEM | null,
  +isGridAvenue?: boolean,
  +isInExploreModal?: boolean,
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  +searchString?: string | null,
|};

export type SectionPropType = {|
  ...DefaultProps,
  +avenueIndex: number,
  +dataLoadedCallback: (sectionId: string, sectionIndex: number, avenueIndex: number, itemCount: number) => void,
  +section: NETGEM_API_V8_SECTION,
  +sectionIndex: number,
|};

export type CompleteSectionPropType = {|
  ...SectionPropType,
  ...ReduxSectionReducerStateType,
  ...ReduxSectionDispatchToPropsType,
|};

export type SectionStateType = {|
  displayType: SectionDisplayType,
  feed: ?NETGEM_API_V8_FEED,
  isCollapsed: boolean,
  isDebugModePlus: boolean,
  isLive: boolean,
  isLoading: boolean,
  isSwiping: boolean,
  itemCountPerPage: number,
  maxPageIndex: number,
  pageIndex: number,
  savedPageIndex?: number,
  sortAndFilter: SortAndFilterType | null,
  updateDimensionsNeeded: boolean,
|};
